import { SET_TRANSLATION_SUBTITLE } from "../actions/types"

const initialState = {
    translationSubtitle: ""
}

function translationSubtitleReducer(state = initialState, action) {
    switch(action.type) {
        case SET_TRANSLATION_SUBTITLE:
            return {
                ...state,
                translationSubtitle: action.payload
            }
        default:
            return state
    }
}

export default translationSubtitleReducer;