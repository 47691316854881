import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Button } from 'react-bootstrap'
import { BiFullscreen } from 'react-icons/bi'
import { socket } from "../../socket";
import Snackbar from '@mui/material/Snackbar';
import { v4 as uuidv4 } from 'uuid';

import './audioRecorder.css'

import { useDispatch, useSelector, useStore } from 'react-redux';
import { saveRecordingBlobOnServer, saveRecordingBlobPartOnServer } from '../../actions/recordingActions'
import { saveProcess } from '../../actions/savingActions'
import { saveSnapshot } from '../../actions/snapshotActions'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import download from 'js-file-download'

import { apiUrl } from '../../constants'

import { getSrtFromJson } from '../../helpers/ParserHelper';
import { Prompt } from 'react-router-dom';
import { fixQuotes, removeSpacesAfterNewLine } from '../../helpers/SentenceHelper';
import ProgressBar from '../progressBar/progressBar';
import Modal from 'react-bootstrap/Modal';
import { translateText } from '../../actions/translateActions';
import { updateTranslatedSubtitles } from '../../actions/translatedSubtitlesActions';
import { setCurrentlyTranscribing, setLastEditedCommentValue, setLastEditedValue } from '../../actions/subtitlePropertiesActions';


const MySwal = withReactContent(Swal)
let screenRecorder
let stream

var recorder = null;
var audioStream = null;
let bufferSize = 2048,
  AudioContext,
  context,
  processor,
  input,
  globalStream;

let finalWord = false,
  removeLastSentence = true,
  streamStreaming = false;

const constraints = {
  audio: true,
  video: false
};


socket.on('notValidKey', () => {
  alert("Google Api Key Not Valid")
})

const AudioRecorder = ({ isPlaying, setIsPlaying, player, subtitlePropsUrl }) => {
  const dispatch = useDispatch()
  const store = useStore()
  const [micState, setMicState] = useState(0)
  const [recordingInterval, setRecordingInterval] = useState(null);
  const [isMicActive, setIsMicActive] = useState(false)
  const language = useSelector(store => store.settingsReducer.language)
  const microphoneKey = useSelector(state => state.userSettingsReducer.microphoneKey)
  const pushHold = useSelector(state => state.settingsReducer.pushHold)
  const translatedSubtitles = useSelector(state => state.translatedSubtitlesReducer.translatedSubtitles)
  const uploadedSubtitles = useSelector(state => state.uploadedSubtitlesReducer.uploadedSubtitles)
  const subtitleName = useSelector(state => state.subtitlePropertiesReducer.subsTitle)
  const currentProject = useSelector(state => state.currentProjectReducer.project)
  const [notValid, setNotValid] = useState(false)
  const [disconected, setDisconected] = useState("")
  const [lastTimestamp, setLastTimestamp] = useState(0)
  const [startTime, setStartTime] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  let [punctation, setPunctationVal] = useState("")
  let translationSubtitle = useSelector(state => state.translationSubtitleReducer.translationSubtitle)
  let gvtInstant = useSelector(state => state.settingsReducer.gvtInstant)
  const lastEdited = useSelector(state => state.subtitlePropertiesReducer.lastEdited)
  const lastEditedValue = useSelector(state => state.subtitlePropertiesReducer.lastEditedValue)
  const lastEditedComment = useSelector(state => state.translatedSubtitlesReducer.commentId)
  const lastEditedCommentValue = useSelector(state => state.subtitlePropertiesReducer.lastEditedCommentValue)

  const setCommentNativeValue = (element, value, isGvtInstant = false, isFinal = false) => {
    if (value === "punctation") {
      let lastValue = element?.value;
      if (punctation === `"`) {
        const quotationsCount = getNumberOfQuotations(element.value)
        if (quotationsCount % 2 === 0) {
          element.value = element.value.replace(/[ \.,?!:"-]$/, '') + " " + punctation;
        }
      } else {
        element.value = element.value.replace(/[ \.,?!:"-]$/, '') + punctation;
      }
      let event = new Event("input", { target: element, bubbles: true });
      event.simulated = true;
      let tracker = element._valueTracker;
      if (isFinal) {
        dispatch(setLastEditedCommentValue(element?.value))
      }
      if (tracker) {
        tracker.setValue(lastValue);
      }
      element.dispatchEvent(event);
    } else if (value === "") {
      let lastValue = element?.value;
      element.value = "";
      let event = new Event("input", { target: element, bubbles: true });
      event.simulated = true;
      let tracker = element._valueTracker;
      if (isFinal) {
        dispatch(setLastEditedCommentValue(element?.value))
      }
      if (tracker) {
        tracker.setValue(lastValue);
      }
      element.dispatchEvent(event);
    }
    else {
      let prefix = "";
      value = value.replaceAll("$NOVI_RED$", "\n");
      const quotationsCount = getNumberOfQuotations(element.value)
      const prefixSymbols = [",", ".", "?", "!", "\n", ":", ";", "-"];
      if (!element.value || element.value === '' || (value[0] === `"` && quotationsCount % 2 === 1)
        || (element.value[element.value.length - 1] === `"` && quotationsCount % 2 === 1)
        || element.value[element.value.length - 1] === `\n`
        || prefixSymbols.includes(value) || prefixSymbols.includes(value[0])) {
        prefix = "";
      } else {
        prefix = " "
      }
      value = prefix + value;
      //let lastValue = element?.value;
      let lastValue = lastEditedCommentValue
      console.log(`Last comment value: ${lastEditedCommentValue}`)
      if (isGvtInstant) {
        if (isFinal) {
          value = (lastEditedCommentValue ?? " ") + value;
          console.log(`Dispatching last edited comment value: ${value}`)
          dispatch(setLastEditedCommentValue(value))
        } else if (lastEditedCommentValue) {
          value = (lastEditedCommentValue ?? " ") + value;
        }
      } else {
        value = lastValue + value;
      }
      value = capitalizeString(value, true);
      value = fixQuotes(value)
      value = removeSpacesAfterNewLine(value);
      element.value = value
      let event = new Event("input", { target: element, bubbles: true });
      event.simulated = true;
      let tracker = element._valueTracker;
      if (tracker) {
        tracker.setValue(lastValue);
      }
      element.dispatchEvent(event);
    }

  }

  const setNativeValue = (element, value, isGvtInstant = false, isFinal = true, previousElementText) => {
    if (value === "punctation") {
      let lastValue = element?.value;
      if (punctation === `"`) {
        const quotationsCount = getNumberOfQuotations(element.value)
        if (quotationsCount % 2 === 0) {
          element.value = element.value.replace(/[ \.,?!:"-]$/, '') + " " + punctation;
        }
      } else {
        element.value = element.value.replace(/[ \.,?!:"-]$/, '') + punctation;
      }
      let event = new Event("input", { target: element, bubbles: true });
      event.simulated = true;
      let tracker = element._valueTracker;
      if (isFinal) {
        dispatch(setLastEditedValue(element?.value))
      }
      if (tracker) {
        tracker.setValue(lastValue);
      }
      element.dispatchEvent(event);
    } else if (value === "") {
      let lastValue = element?.value;
      element.value = "";
      let event = new Event("input", { target: element, bubbles: true });
      event.simulated = true;
      let tracker = element._valueTracker;
      if (isFinal) {
        dispatch(setLastEditedValue(element?.value))
      }
      if (tracker) {
        tracker.setValue(lastValue);
      }
      element.dispatchEvent(event);
    }
    else {
      let prefix = "";
      value = value.replaceAll("$NOVI_RED$", "\n");
      const quotationsCount = getNumberOfQuotations(element.value)
      const prefixSymbols = [",", ".", "?", "!", "\n", ":", ";", "-"];
      if (!element.value || element.value === '' || (value[0] === `"` && quotationsCount % 2 === 1)
        || (element.value[element.value.length - 1] === `"` && quotationsCount % 2 === 1)
        || element.value[element.value.length - 1] === `\n`
        || prefixSymbols.includes(value) || prefixSymbols.includes(value[0])) {
        prefix = "";
      } else {
        prefix = " "
      }
      value = prefix + value;
      let lastValue = element?.value;
      if (isGvtInstant) {
        if (isFinal) {
          value = (lastEditedValue ?? " ") + value;
          dispatch(setLastEditedValue(value))
        } else if (lastEditedValue) {
          value = (lastEditedValue ?? " ") + value;
        }
      } else {
        value = lastValue + value;
      }
      let punctuationSymbols = ["?", ".", "!"]
      if (previousElementText && punctuationSymbols.includes(previousElementText[previousElementText.length - 1])) {
        value = capitalizeString(value, true);
      } else if (!previousElementText) {
        value = capitalizeString(value, true);
      } else {
        value = capitalizeString(value, false);
      }
      value = fixQuotes(value)
      value = removeSpacesAfterNewLine(value);
      element.value = value
      let event = new Event("input", { target: element, bubbles: true });
      event.simulated = true;
      let tracker = element._valueTracker;
      if (tracker) {
        tracker.setValue(lastValue);
      }
      element.dispatchEvent(event);
      if (isFinal || !isGvtInstant) {
        dispatch(setCurrentlyTranscribing(null))
      }
    }
  }

  const removeDoubleSpaces = (str) => {
    return str.replace(/ +/g, " ").replace(/ \./g, "");
  }

  const capitalizeString = (_string, firstLetterCapitalized) => {
    let string = null;
    if (_string[_string.length - 1] === "\n") {
      string = _string.trimStart();
    } else {
      string = _string.trim();
    }
    console.log(`First string: ${string}`)
    let punctuationSymbols = ["?", ".", "!"]
    let fixedString = "";
    let uppercaseIndexes = (firstLetterCapitalized) ? [0] : [];
    for (let i = 0; i < string.length; i++) {
      const letter = string[i];
      if (punctuationSymbols.includes(letter) && i + 2 < string.length) {
        uppercaseIndexes.push(i + 2);
      }
    }
    for (let i = 0; i < string.length; i++) {
      const letter = string[i];
      if (uppercaseIndexes.includes(i)) {
        fixedString += letter.toUpperCase();
      } else {
        fixedString += letter;
      }
    }
    console.log(`Exit string: ${fixedString}`)
    return fixedString;
  }

  const capitalizeTextAreas = (document) => {
    let textAreaElems = [...document.querySelectorAll(".highlight-textarea")]
    const punctuationSymbols = ["?", ".", "!"]
    for (let i = 0; i < textAreaElems.length; i++) {
      const text = textAreaElems[i].textContent
      if (i === 0) {
        const convertedString = capitalizeString(text, true)
        textAreaElems[i].value = convertedString;
        continue;
      }
      const pastText = textAreaElems[i - 1].textContent
      if (punctuationSymbols.includes(pastText[pastText.length - 1])) {
        const convertedString = capitalizeString(text, true)
        textAreaElems[i].value = convertedString;
      } else {
        const convertedString = capitalizeString(text, false)
        textAreaElems[i].value = convertedString;
      }
    }
  }

  const punctations = ['.', '?', '!', ':', '"', '-'];

  const capitalizationFix = (str) => {
    let finalWord = "";
    for (let i = 0; i < str.length; i++) {
      if (punctations.includes(str[i - 2]) && str[i - 1] == " ") {
        finalWord += str[i].toUpperCase();
      } else if (punctations.includes(str[i - 1]) && str[i] != " ") {
        finalWord += " " + str[i].toUpperCase();
      } else if (i == str.length - 1 && str[i] == " ") {
        continue;
      } else {
        finalWord += str[i];
      }
    }
    return finalWord;
  }


  useEffect(() => {
    // Function to update the elapsed time
    const updateElapsedTime = () => {
      setElapsedTime(Date.now() - startTime);
    };

    // Set an interval to update the timer every second
    const intervalId = setInterval(updateElapsedTime, 1000);

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [startTime]);

  useEffect(() => {
    socket.on('speechData', function (transcription) {
      setStartTime(Date.now())
      if (lastTimestamp && lastTimestamp > transcription.timestamp) {
        return
      } else {
        setLastTimestamp(transcription.timestamp)
      }
      if (!transcription.transcript || transcription.transcript.trim().length === 0) {
        return
      }
      let selectedComment = store.getState().translatedSubtitlesReducer.commentId
      console.log(`Selektirani komentar jest ${selectedComment}`)
      if (!selectedComment) {
        console.log(`Writing into subtitle cell`)
        let last = store.getState().subtitlePropertiesReducer.lastEdited
        let textareaElem = document.getElementById(last).childNodes[0].childNodes[1].childNodes[1].childNodes[1]
        let previousTextAreaElement = last === 1 ? null : document.getElementById(last - 1).childNodes[0].childNodes[1].childNodes[1].childNodes[1]

        if (gvtInstant) {
          console.log(`Nova transkripcija jeste: ${transcription.transcript} isFinal: ${transcription.isFinal}`)
          setNativeValue(textareaElem, transcription.transcript, true, transcription.isFinal, previousTextAreaElement?.textContent);
        } else {
          let newTranscription = translationSubtitle + transcription.transcript
          setPunctationVal("");
          if (!transcription.transcript.endsWith('-')) {
            newTranscription += " "
          }
          newTranscription = removeDoubleSpaces(newTranscription);

          if (last) {
            setNativeValue(textareaElem, newTranscription, false, true, previousTextAreaElement?.textContent)
          }
          textareaElem.focus()
        }
      }
      else {
        let last = selectedComment
        let textareaElem = document.querySelector(`textarea[data-sub-id="${last}"]`)
        if (gvtInstant) {
          setCommentNativeValue(textareaElem, transcription.transcript, true, transcription.isFinal)
        } else {
          let newTranscription = translationSubtitle + transcription.transcript
          setPunctationVal("");
          if (!transcription.transcript.endsWith('-')) {
            newTranscription += " "
          }
          newTranscription = removeDoubleSpaces(newTranscription);

          if (last) {
            setCommentNativeValue(textareaElem, newTranscription, false, true)
          }
          textareaElem.focus()
        }
      }
    });

    socket.on('disconnect', () => {
      dispatch(setCurrentlyTranscribing(null))
      stopRecording()
    })

    return (() => {
      socket.off('speechData');
      socket.off('disconnect');
    });
  }, [gvtInstant, lastEditedValue, lastEditedCommentValue]);

  useEffect(() => {
    dispatch(
      setLastEditedValue(document?.getElementById(lastEdited)?.childNodes[0]?.childNodes[1]?.childNodes[1]?.childNodes[1]?.value)
    )
  }, [lastEdited]);

  useEffect(() => {
    const value = document.querySelector(`textarea[data-sub-id="${lastEditedComment}"]`)
    if (!value) {
      return;
    }
    dispatch(setLastEditedCommentValue(value.value))
  }, [lastEditedComment])

  var downsampleBuffer = function (buffer, sampleRate, outSampleRate) {
    if (outSampleRate == sampleRate) {
      return buffer;
    }
    if (outSampleRate > sampleRate) {
      throw "downsampling rate show be smaller than original sample rate";
    }
    var sampleRateRatio = sampleRate / outSampleRate;
    var newLength = Math.round(buffer.length / sampleRateRatio);
    var result = new Int16Array(newLength);
    var offsetResult = 0;
    var offsetBuffer = 0;
    while (offsetResult < result.length) {
      var nextOffsetBuffer = Math.round((offsetResult + 1) * sampleRateRatio);
      var accum = 0, count = 0;
      for (var i = offsetBuffer; i < nextOffsetBuffer && i < buffer.length; i++) {
        accum += buffer[i];
        count++;
      }

      result[offsetResult] = Math.min(1, accum / count) * 0x7FFF;
      offsetResult++;
      offsetBuffer = nextOffsetBuffer;
    }
    return result.buffer;
  }

  function stopRecording() {
    setIsMicActive(false)
    streamStreaming = false;
    socket.emit('endGoogleCloudStream', '');

    try {
      let track = globalStream.getTracks()[0];
      track.stop();
      input.disconnect(processor);
      processor.disconnect(context.destination);
      context.close().then(function () {
        input = null;
        processor = null;
        context = null;
        AudioContext = null;
      });
    } catch (err) {
      console.log(err)
    }
  }

  const startStream = () => {
    setIsMicActive(true)
    socket.emit('startGoogleCloudStream', '');
    streamStreaming = true;
    AudioContext = window.AudioContext || window.webkitAudioContext;
    context = new AudioContext({
      latencyHint: 'interactive',
    });
    processor = context.createScriptProcessor(bufferSize, 1, 1);
    processor.connect(context.destination);
    context.resume();

    var handleSuccess = function (stream) {
      globalStream = stream;
      input = context.createMediaStreamSource(stream);
      input.connect(processor);

      processor.onaudioprocess = function (e) {
        microphoneProcess(e);
      };
    };

    navigator.mediaDevices.getUserMedia(constraints)
      .then(handleSuccess);
  }

  function initRecording() {
    navigator.permissions.query(
      { name: 'microphone' }
    )
      .then(res => {
        if (res.state != "granted") {
          Swal.fire({
            position: 'center',
            icon: 'info',
            title: "You have to allow microphone usage. (Left corner)",
            showConfirmButton: false,
            timer: 2250
          })
          navigator.mediaDevices.getUserMedia({ audio: true })
            .then(res => {
              Swal.fire({
                position: 'center',
                icon: 'success',
                title: "You have enabled microphone!",
                showConfirmButton: false,
                timer: 1500
              })
                .then(() => {
                  startStream()
                })
            })
            .catch(err => {
              Swal.fire({
                position: 'center',
                icon: 'warning',
                title: "You have to enable microphone permissions to use speech to text functionality.",
                showConfirmButton: true,
              })
            })
        } else {
          startStream()
        }
      })
  }

  function microphoneProcess(e) {
    var left = e.inputBuffer.getChannelData(0);
    var left16 = downsampleBuffer(left, 44100, 16000)
    socket.emit('userSpeaking', left16);
  }

  function handleClick() {
    socket.emit('languageChange', language.lang)
    if (!isMicActive) {
      initRecording()
    } else {
      stopRecording()
    }
  }

  const downHandler = ({ keyCode, ctrlKey, repeat }) => {
    if (!repeat) {
      if (ctrlKey && keyCode === microphoneKey) {
        handleClick()
      }
    } else {
      return
    }
  }
  const upHandler = ({ keyCode, ctrlKey }) => {
    if (keyCode == microphoneKey && ctrlKey) {
      handleClick()
    }
  }

  const handleTranslateAll = async () => {
    Swal.fire({
      title: 'Translating...',
      html: "",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading()
      },
    })
    const translatedSubs = await Promise.all(uploadedSubtitles.map(async subtitle => {
      const data = await dispatch(translateText(subtitle.text, "hr_HR"))
      return { ...subtitle, text: data.translatedText }
    }))
    await dispatch(saveProcess(uploadedSubtitles, translatedSubs, subtitleName, currentProject._id))
    Swal.close();
  }

  const handleDownload = () => {
    axios({
      url: `${apiUrl}/download-subtitles`,
      method: "POST",
      data: {
        subtitles: translatedSubtitles,
        subtitleName,
      }
    })
      .then(res => {
        getSubtitles(subtitleName)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const handleClear = () => {
    let last = store.getState().subtitlePropertiesReducer.lastEdited
    if (!last) {
      return;
    }
    setNativeValue(document.getElementById(last).childNodes[0].childNodes[1].childNodes[1].childNodes[1], "")
    dispatch(setLastEditedValue(""))
  }

  const getSubtitles = (subtitleName) => {
    axios.get(`${apiUrl}/download-subtitles/get-file`, {
      params: {
        subtitleName
      }
    })
      .then(res => {
        download(res.data, `${subtitleName}-translated_subtitles.srt`)
      })
      .catch(err => {
        console.log(err)
      })
  }

  const handleSavingProcess = () => {
    dispatch(saveProcess(uploadedSubtitles, translatedSubtitles, subtitleName, currentProject._id))
      .then(() => {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Your work has been saved',
          showConfirmButton: false,
          timer: 1500
        })
      })
      .catch(() => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong, try again!',
        })
      })
  }

  const saveSnapshotHandler = async () => {
    const { value: snapshotName } = await Swal.fire({
      title: 'Name your snapshot below',
      input: 'text',
      inputLabel: 'Snapshot title',
      inputPlaceholder: 'Almost done with 1st half'
    });
    if (snapshotName) {
      dispatch(saveSnapshot(currentProject, translatedSubtitles, snapshotName))
        .then(() => {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Snapshot saved!',
            showConfirmButton: false,
            timer: 1500
          })
        })
        .catch(() => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong, try again!',
          })
        });
    }
  }

  const handleSubtitlePreview = () => {
    let srtSubtitles = getSrtFromJson(translatedSubtitles)
    let video = document.querySelector("video")
    let tracks = video.querySelectorAll("track").forEach(e => e.remove())
    let track = document.createElement("track");
    track.kind = "subtitles";
    track.label = "English";
    track.srclang = "en";
    track.setAttribute("default", "");
    track.addEventListener("load", function () {
      track.mode = "showing";
      video.textTracks[0].mode = "showing";
    });
    var vttBlob = new Blob([srtSubtitles], {
      type: 'text/plain'
    });
    console.log("BLOB: ", vttBlob)
    track.src = URL.createObjectURL(vttBlob);
    video.appendChild(track);
  }

  const setDefaultUploadedSubtitles = () => {
    var checkExist = setInterval(function () {
      if (document.querySelector('video')) {
        let srtSubtitles = getSrtFromJson(uploadedSubtitles)
        clearInterval(checkExist)
        let video = document.querySelector("video")
        let tracks = video.querySelectorAll("track").forEach(e => e.remove())
        let track = document.createElement("track");
        track.kind = "subtitles";
        track.label = "English";
        track.srclang = "en";
        track.setAttribute("default", "");
        track.addEventListener("load", function () {
          track.mode = "showing";
          video.textTracks[0].mode = "showing";
        });
        var vttBlob = new Blob([srtSubtitles], {
          type: 'text/plain'
        });
        track.src = URL.createObjectURL(vttBlob);
        console.log("DODAVANJE: ", URL.createObjectURL(vttBlob))
        video.appendChild(track);
      }
    }, 100);
  }

  const scrollToLast = () => {
    let searchIndex = lastEdited - 1
    if (lastEdited) {
      var myElement = document.getElementById(lastEdited - 1);
      if (myElement) {
        var topPos = myElement.offsetTop;
        if (document.getElementById('subtitles-div')) {
          document.getElementById('subtitles-div').scrollTo({ top: topPos, behavior: 'smooth' })
        }
      }
    }
  }

  const getNumberOfQuotations = (string) => {
    let count = 0;
    for (let i = 0; i < string.length; i++) {
      if (string[i] === `"`) {
        count++
      }
    }
    return count;
  }

  const setPunctation = (punctationValue) => {
    let panVal = punctationValue;
    let last = store.getState().subtitlePropertiesReducer.lastEdited
    const lastEditedText = document.getElementById(last).childNodes[0].childNodes[1].childNodes[1].childNodes[1].value
    const numberOfQuotations = getNumberOfQuotations(lastEditedText)
    if ((punctationValue !== `"` && punctationValue !== "\n") || numberOfQuotations % 2 === 1) {
      panVal += " ";
    }
    const promise = new Promise((resolve, reject) => {

      punctation = panVal
      // console.log(punctation)
      // setTimeout(() =>{
      resolve(setPunctationVal(panVal))
      // },300)
    });
    promise.then(setPunctationVal(panVal))
    promise.then(setNativeValue(document.getElementById(last).childNodes[0].childNodes[1].childNodes[1].childNodes[1], "punctation"))

    // switch(punctationVal){
    //   case 0 : {await setPunctationVal(".");setNativeValue(document.getElementById(last).childNodes[0].childNodes[1].childNodes[1].childNodes[1],"punctation" );break;}
    //   case 1 : {await setPunctationVal(",");setNativeValue(document.getElementById(last).childNodes[0].childNodes[1].childNodes[1].childNodes[1],"punctation" );break;}
    //   case 2 : {await setPunctationVal("?");setNativeValue(document.getElementById(last).childNodes[0].childNodes[1].childNodes[1].childNodes[1],"punctation" );break;}
    //   case 3 : {await setPunctationVal("!");setNativeValue(document.getElementById(last).childNodes[0].childNodes[1].childNodes[1].childNodes[1],"punctation" );break;}
    //   case 4 : {await setPunctationVal(':');setNativeValue(document.getElementById(last).childNodes[0].childNodes[1].childNodes[1].childNodes[1],"punctation" );break;}
    //   case 5 : {await setPunctationVal('"');setNativeValue(document.getElementById(last).childNodes[0].childNodes[1].childNodes[1].childNodes[1],"punctation" );break;}
    //   case 6 : {await setPunctationVal("-");setNativeValue(document.getElementById(last).childNodes[0].childNodes[1].childNodes[1].childNodes[1],"punctation" );break;}
    // }
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler, false)
    if (pushHold) {
      window.addEventListener('keyup', upHandler, false)
    } else {
      window.removeEventListener('keyup', upHandler, false)
    }
    return () => {
      window.removeEventListener('keydown', downHandler, false)
      window.removeEventListener('keyup', upHandler, false)
    }
  }, [isMicActive, microphoneKey, pushHold])

  useEffect(() => {
    setDefaultUploadedSubtitles()
  }, [uploadedSubtitles])

  useEffect(() => {
    scrollToLast()
  }, [lastEdited])

  return (
    <div className="recording-div">
      <HomeControls
        handleClick={handleClick}
        isPlaying={isPlaying}
        setIsPlaying={setIsPlaying}
        pushHold={pushHold}
        isMicActive={isMicActive}
        handleSavingProcess={handleSavingProcess}
        saveSnapshotHandler={saveSnapshotHandler}
        handleDownload={handleDownload}
        handleTranslateAll={handleTranslateAll}
        handleClear={handleClear}
        handleSubtitlePreview={handleSubtitlePreview}
        setDefaultUploadedSubtitles={setDefaultUploadedSubtitles}
        player={player}
        setPunctation={setPunctation}
        subtitlePropsUrl={subtitlePropsUrl}
        store={store}
        uploadedSubtitles={uploadedSubtitles}
        currentProject={currentProject}
      />
    </div>
  );
}

export default AudioRecorder;

let startTime = ""

const HomeControls = ({
  handleClick,
  handleSavingProcess,
  saveSnapshotHandler,
  handleDownload,
  handleTranslateAll,
  handleClear,
  handleSubtitlePreview,
  setDefaultUploadedSubtitles,
  pushHold,
  isMicActive,
  isPlaying,
  setIsPlaying,
  player,
  setPunctation,
  subtitlePropsUrl,
  store,
  uploadedSubtitles,
  currentProject
}) => {
  const dispatch = useDispatch()
  const [progressBarPercentage, setProgressBarPercentage] = useState(0);
  const [progressStatus, setProgressStatus] = useState(null);
  const [previewStatus, setPreviewStatus] = useState(false)
  const [isRecordingScreen, setIsRecordingScreen] = useState(false)
  const [isUploading, setIsUploading] = useState(false);
  const [recordingInterval, setRecordingInterval] = useState(null);
  const [recordingStartTime, setRecordingStartTime] = useState(0);
  const autoRecord = useSelector(store => store.settingsReducer.autoRecord)
  const [snackBarState, setSnackBarState] = React.useState({
    open: false,
    vertical: 'bottom',
    horizontal: 'center',
  });

  const alertUser = e => {
    e.preventDefault()
    e.returnValue = ''
  }

  const skipVideoTime = (amount) => {
    console.log("Skipping for: ", amount)
    const currentTime = player.current.player.getCurrentTime()
    const skipped = currentTime + amount
    if (player.current.player.player.player.seekTo) {
      player.current.player.player.player.seekTo(skipped)
    } else {
      player.current.player.player.player.currentTime = skipped
    }

  }

  const openFullscreen = (elem) => {
    console.log(subtitlePropsUrl)
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) { /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE11 */
      elem.msRequestFullscreen();
    } else {
      console.log(player)
      console.log(subtitlePropsUrl)
    }
  }

  const mergeAudioStreams = (desktopStream, voiceStream) => {
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    const context = new AudioContext();
    const destination = context.createMediaStreamDestination();
    let hasDesktop = false;
    let hasVoice = false;
    if (desktopStream && desktopStream.getAudioTracks().length > 0) {
      const source1 = context.createMediaStreamSource(desktopStream);
      const desktopGain = context.createGain();
      desktopGain.gain.value = 0.7;
      source1.connect(desktopGain).connect(destination);
      hasDesktop = true;
    }

    if (voiceStream && voiceStream.getAudioTracks().length > 0) {
      const source2 = context.createMediaStreamSource(voiceStream);
      const voiceGain = context.createGain();
      voiceGain.gain.value = 0.7;
      source2.connect(voiceGain).connect(destination);
      hasVoice = true;
    }

    return (hasDesktop || hasVoice) ? destination.stream.getAudioTracks() : [];
  };

  function parseMillisecondsIntoReadableTime(milliseconds) {
    //Get hours from milliseconds
    var hours = milliseconds / (1000 * 60 * 60);
    var absoluteHours = Math.floor(hours);
    var h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours;

    //Get remainder from hours and convert to minutes
    var minutes = (hours - absoluteHours) * 60;
    var absoluteMinutes = Math.floor(minutes);
    var m = absoluteMinutes > 9 ? absoluteMinutes : '0' + absoluteMinutes;

    //Get remainder from minutes and convert to seconds
    var seconds = (minutes - absoluteMinutes) * 60;
    var absoluteSeconds = Math.floor(seconds);
    var s = absoluteSeconds > 9 ? absoluteSeconds : '0' + absoluteSeconds;


    return h + ':' + m + ':' + s;
  }

  const stopCapture = () => {
    console.log("screenRecorder: ", screenRecorder)
    if (screenRecorder) {
      screenRecorder.stop();
      if (stream) {
        setIsRecordingScreen(false)
        window.removeEventListener("beforeunload", alertUser)
        stream.getTracks().forEach(s => s.stop())
      }
    }
  }
  async function startCapture(displayMediaOptions) {
    let chunks = [];
    let chunksUploaded = 0;
    let chunksTotal = 0;
    let displayStream = null;
    let voiceStream = null;
    let finishInterval = null;
    let uploadingPart = false;
    const recordingId = uuidv4().replaceAll("-", "");

    const { value: name } = await Swal.fire({
      title: 'Input name for recording',
      input: 'text',
      inputLabel: 'Your name for recording',
      inputPlaceholder: 'Recording - Movie XYZ'
    });

    try {
      socket.emit("joinUpload", { room: `upload-${recordingId}` })
      socket.on("uploadProgress", args => {
        const progress = args.progress;
        const status = args.status;
        setProgressStatus(status);
        setProgressBarPercentage((progress * 100).toFixed(1))
      })
      const devices = await navigator.mediaDevices.enumerateDevices();
      const hasMic = devices.some(device => device.kind === 'audioinput');
      displayStream = await navigator.mediaDevices.getDisplayMedia({ audio: true, video: true });
      if (hasMic) {
        voiceStream = await navigator.mediaDevices.getUserMedia({ video: false, audio: true });
        const tracks = [...displayStream.getTracks(), ...voiceStream.getAudioTracks()];
        stream = new MediaStream(tracks);
      } else {
          stream = displayStream
      }


      screenRecorder = new MediaRecorder(stream, { bitsPerSecond: 2500000 });
      screenRecorder.ondataavailable = (e) => {
        if (!e.data || e.data.size === 0) {
          return
        }
        chunksTotal++;
        uploadingPart = true;
        const progress = chunksUploaded / chunksTotal
        setProgressStatus("chunk")
        setProgressBarPercentage((progress * 100).toFixed(1))
        const blob = e.data
        const timeElapsed = parseMillisecondsIntoReadableTime(Date.now() - startTime);
        dispatch(saveRecordingBlobPartOnServer(blob, recordingId, name, timeElapsed))
          .then(res => {
            chunksUploaded++;
            uploadingPart = false;
            console.log("saved recording part")
          })
          .catch(err => {
            console.log("error while saving recording part")
          })
      };
      screenRecorder.start(10000);
      startTime = Date.now()
      setIsRecordingScreen(true);
      window.addEventListener("beforeunload", alertUser)

      if (screenRecorder == undefined) {
        console.log("FAILED TO START");
      }

      screenRecorder.onstop = async () => {
        setIsRecordingScreen(false)
        setIsUploading(true);
        const timeElapsed = parseMillisecondsIntoReadableTime(Date.now() - startTime);
        window.removeEventListener("beforeunload", alertUser);
        let isFinished = chunksTotal === chunksUploaded;
        if (isFinished) {
          console.log("finishing up")
          dispatch(saveRecordingBlobOnServer(recordingId, name, timeElapsed))
            .then(res => {
              setIsUploading(false)
              setProgressBarPercentage(0)
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: "Recording saved",
                showConfirmButton: false,
                timer: 1500,
              });
            })
            .catch(err => {
              Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: err,
                showConfirmButton: false,
                timer: 1500
              });
            })
        } else {
          finishInterval = setInterval(() => {
            let isFinished = chunksTotal === chunksUploaded;
            if (!isFinished) {
              return;
            }
            clearInterval(finishInterval)
            console.log("finishing up")
            dispatch(saveRecordingBlobOnServer(recordingId, name, timeElapsed))
              .then(res => {
                setIsUploading(false)
                setProgressBarPercentage(0)
                Swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: "Recording saved",
                  showConfirmButton: false,
                  timer: 1500,
                });
              })
              .catch(err => {
                Swal.fire({
                  position: 'top-end',
                  icon: 'error',
                  title: err,
                  showConfirmButton: false,
                  timer: 1500
                });
              })
          }, 1000)
        }

      };
    } catch (err) {
      console.error("Error: " + err);
      setIsRecordingScreen(false)
      window.removeEventListener("beforeunload", alertUser);
    }
  }

  const renderProgressStatus = (status) => {
    switch (status) {
      case 'chunk':
        return "Preparing recording..."
      case 'fix':
        return "Parsing recording..."
      case 'final':
        return "Uploading recording..."
      default:
        return "Uploading recording..."
    }
  }

  const nextSubtitle = () => {
    let lastEdited = store.getState().subtitlePropertiesReducer.lastEdited
    if (uploadedSubtitles.length > lastEdited) {
      document.getElementsByClassName('highlight-textarea')[lastEdited].focus()
    }
  }

  const previousSubtitle = () => {
    let lastEdited = store.getState().subtitlePropertiesReducer.lastEdited
    if (lastEdited > 0) {
      document.getElementsByClassName('highlight-textarea')[lastEdited - 2].focus()
    }
  }

  useEffect(() => {
    if (autoRecord) {
      startCapture()
    }
  }, [])

  return (
    <div>
      <Modal
        show={isUploading}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          {renderProgressStatus(progressStatus)}
        </Modal.Header>
        <Modal.Body>
          <ProgressBar percentage={progressBarPercentage} />
        </Modal.Body>
      </Modal>
      <Prompt when={isRecordingScreen} message="Warning! Recording is in progress!" />
      <p>Video controls</p>
      <Snackbar
        className='recording-snackbar'
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={isRecordingScreen}
        message="Recording in progress."
      />
      <div className="video-controls-div">
        <button className="video-controls-btn" onClick={() => skipVideoTime(-5)}>-5</button>
        <button className="video-controls-btn" onClick={() => skipVideoTime(-1)}>-1</button>
        <button className="video-controls-btn" onClick={() => setIsPlaying(!isPlaying)}>{isPlaying ? "Pause" : "Play"}</button>
        <button className="video-controls-btn" onClick={() => skipVideoTime(+1)}>+1</button>
        <button className="video-controls-btn" onClick={() => skipVideoTime(+5)}>+5</button>
        <button className="video-controls-btn" onClick={() => openFullscreen(player.current.player.player.player)}><BiFullscreen /></button>
      </div>
      <hr />
      {!currentProject?.isSnapshot &&
        <>
          <p>Project options</p>
          <div className="project-punctation-div">
            <button className="np-btn" onClick={() => setPunctation(".")}>.</button>
            <button className="np-btn" onClick={() => setPunctation(",")}>,</button>
            <button className="np-btn" onClick={() => setPunctation("?")}>?</button>
            <button className="np-btn" onClick={() => setPunctation("!")}>!</button>
            <button className="np-btn" onClick={() => setPunctation(":")}>:</button>
            <button className="np-btn" onClick={() => setPunctation('"')}>"</button>
            <button className="np-btn" onClick={() => setPunctation("-")}>-</button>
            <button className="np-btn" onClick={() => setPunctation("\n")}>New Line</button>
          </div>
          <div className="project-controls-div">
            <div className="speak-short-div">
              <Button variant="secondary" className="np-btn prev-btn" onClick={previousSubtitle}>Previous</Button>
              <div className="spec-div-short">
                <button className={isMicActive ? "btn-spec-short-record btn-spec-short" : "btn-spec-short"}
                  variant={isMicActive ? 'danger' : 'success'}
                  onClick={() => !pushHold ? handleClick() : ""}
                  onMouseDown={() => pushHold ? handleClick() : ""}
                  onMouseUp={() => pushHold ? handleClick() : ""}
                >{isMicActive ? 'Stop' : 'SpeakSubz'}</button>
              </div>
              <Button variant="secondary" className="np-btn next-btn" onClick={nextSubtitle}>Next</Button>
            </div>

            <div className="shortcuts-div-video-audio" style={{ marginTop: "-15px", marginBottom: 0 }}>
              <Button className="btn-settings-shortcut"
                variant="info"
                onClick={() => handleSavingProcess()}
              >Save</Button>
              <Button className="btn-settings-shortcut"
                onClick={() => handleClear()}
              >Clear</Button>
              <Button className="btn-settings-shortcut"
                onClick={() => handleTranslateAll()}
              >BETA - Translate</Button>
              <Button className="btn-settings-shortcut"
                onClick={() => handleDownload()}
              >Download</Button>
              <Button className="btn-settings-shortcut"
                variant="primary"
                onClick={() => {
                  setPreviewStatus(prevState => !prevState)
                  previewStatus ? setDefaultUploadedSubtitles() : handleSubtitlePreview()
                }}
              >{previewStatus ? 'Normal' : 'Preview'}</Button>
              <Button variant={isRecordingScreen ? "danger btn-settings-shortcut" : "success btn-settings-shortcut"} onClick={isRecordingScreen ? stopCapture : startCapture}>{isRecordingScreen ? "Stop" : "Record"}</Button>
              {/* <Button variant="info" onClick={stopCapture}>Stop</Button> */}
              <Button className="btn-settings-shortcut"
                variant="info"
                onClick={() => saveSnapshotHandler()}
              >Save as</Button>
            </div>
          </div>
        </>
      }
      {currentProject?.isSnapshot &&
        <Button style={{ marginTop: "25px" }} onClick={() => handleDownload()}>Download</Button>
      }
    </div>
  )
}
