import './homeSettings.css';
import React, { useState, useEffect } from 'react'
import NumericInput from 'react-numeric-input'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { Dropdown, DropdownButton, Form, Row, Col, Button, Fade } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { settingsTypes } from '../../actions/types';
import { ProSidebar, Menu, MenuItem, SubMenu, SidebarHeader } from 'react-pro-sidebar'
import ReactTooltip from 'react-tooltip';
import { BsInfoCircle } from 'react-icons/bs'
import { isMobile, mobileModel } from 'react-device-detect'

import 'react-pro-sidebar/dist/css/styles.css';

import { AiFillSetting, AiOutlineTranslation, AiOutlineVideoCamera } from 'react-icons/ai';
import { BiCollapse, BiExpand } from 'react-icons/bi'

import {
    toggleSpeechToText,
    changeLanguage,
    changeReadingSpeed,
    toggleEditTitle,
    changeTitleFontSize,
    changeEditFontSize,
    toggleSourceTitle,
    changeRowLengthValue,
    toggleSpellChecker,
    togglePunctation,
    toggleGvtInstant,
    togglePushHold,
    toggleAutoSave,
    setSettingsFromDatabase,
    toggleHighlightPhrase,
    toggleHighlightProblematicWords,
    toggleAutoRecord,
    setSettingsFromLocalStorage
} from '../../actions/settingsActions';

import { socket } from "../../socket";

const HomeSettings = () => {
    const dispatch = useDispatch()

    const [sidebarCollapsed, setSidebarCollapsed] = useState(true)

    const sourceTitle = useSelector(store => store.settingsReducer.sourceTitle)
    const editTitle = useSelector(store => store.settingsReducer.editTitle)
    const titleFontSize = useSelector(store => store.settingsReducer.titleFontSize)
    const editFontSize = useSelector(store => store.settingsReducer.editFontSize)
    const rowLengthValue = useSelector(store => store.settingsReducer.rowLengthValue)
    const readingSpeed = useSelector(store => store.settingsReducer.readingSpeed)
    const speechToText = useSelector(store => store.settingsReducer.speechToText)
    const language = useSelector(store => store.settingsReducer.language)
    const punctation = useSelector(store => store.settingsReducer.punctation)
    const spellChecker = useSelector(store => store.settingsReducer.spellChecker)
    const gvtInstant = useSelector(store => store.settingsReducer.gvtInstant)
    const pushHold = useSelector(store => store.settingsReducer.pushHold)
    const autoSave = useSelector(store => store.settingsReducer.autoSave)
    const autoRecord = useSelector(store => store.settingsReducer.autoRecord)
    const highlightPhrase = useSelector(store => store.settingsReducer.highlightPhrase)
    const highlightProblematicWords = useSelector(store => store.settingsReducer.highlightProblematicWords)

    const languages = useSelector(store => store.settingsReducer.languages)
    let showSidebar = useSelector(store => store.settingsReducer.showSidebar)
    if (!isMobile) showSidebar = true;
    const getSettingsFromDatabase = () => {
        dispatch(setSettingsFromDatabase())
    }

    const getSettingsFromLocalStorage = () => {
        dispatch(setSettingsFromLocalStorage())
    }

    const toggleSidebar = () => {
        setSidebarCollapsed(!sidebarCollapsed)
    }

    useEffect(() => {
        getSettingsFromLocalStorage()
        getSettingsFromDatabase()
        console.log(showSidebar)
        console.log(mobileModel)
    }, [])

    useEffect(() => {

        document.querySelectorAll('.col').forEach(elem => {
            elem.addEventListener('click', () => {
                setSidebarCollapsed(true)
            })
        })

        return () => {
            document.querySelectorAll('.col').forEach(elem => {
                elem.removeEventListener('click', () => { })
            })
        }
    }, [])

    return (
        <>
            {isMobile &&

                <div className={isMobile ? "pro-sidebar-homesettings-mobile" : "pro-sidebar-homesettings"}>
                    <Fade in={showSidebar}>
                        <ProSidebar collapsed={sidebarCollapsed} >
                            <ReactTooltip
                                effect="solid"
                                type='info'
                                multiline="true"
                            />
                            <SidebarHeader>
                                Settings
                            </SidebarHeader>
                            {sidebarCollapsed ?
                                (
                                    <Button className="expand-collpase-btn" size="sm" variant="light" onClick={() => toggleSidebar()}>Expand  <BiExpand /></Button>
                                    // <span onClick={() => toggleSidebar()}> 
                                    //     Expand

                                    // </span>
                                ) : (
                                    <Button className="expand-collpase-btn" size="sm" variant="light" onClick={() => toggleSidebar()}>Colapse  <BiCollapse /></Button>
                                    // <span onClick={() => toggleSidebar()}>
                                    //     Collapse
                                    //     <BiCollapse/>
                                    // </span>
                                )
                            }
                            <Menu iconShape="square">
                                <SubMenu
                                    title="Video settings"
                                    icon={<AiOutlineVideoCamera />}
                                >
                                    <MenuItem>
                                        Coming soon...
                                        {/* Source Title
                        <BootstrapSwitchButton
                            checked={sourceTitle}
                            onlabel="ON"
                            offlabel="OFF"
                            size="sm"
                            onChange={() => dispatch(toggleSourceTitle(!sourceTitle))}>
                        </BootstrapSwitchButton>
                    </MenuItem>
                    <MenuItem>
                    Edit Title
                        <BootstrapSwitchButton
                            checked={editTitle}
                            onlabel="ON"
                            offlabel="OFF"
                            size="sm"
                            onChange={() => dispatch(toggleEditTitle(!editTitle))}
                        >
                        </BootstrapSwitchButton> */}
                                    </MenuItem>
                                </SubMenu>

                                <SubMenu
                                    title="Font settings"
                                    icon={<AiFillSetting />}
                                >
                                    <MenuItem>
                                        Title Font Size
                                        <NumericInput
                                            className="form-control"
                                            value={titleFontSize}
                                            onChange={(input) =>
                                                dispatch(changeTitleFontSize(input))
                                            }
                                        />
                                    </MenuItem>
                                    <MenuItem>
                                        Edit Font Size
                                        <NumericInput
                                            className="form-control"
                                            value={editFontSize}
                                            onChange={(input) =>
                                                dispatch(changeEditFontSize(input))
                                            }
                                        />
                                    </MenuItem>
                                    <MenuItem>
                                        Row Length Value
                                        <NumericInput
                                            className="form-control"
                                            value={rowLengthValue}
                                            onChange={(input) =>
                                                dispatch(changeRowLengthValue(input))
                                            }
                                        />
                                    </MenuItem>
                                    <MenuItem>
                                        Reading speed <BsInfoCircle data-tip="Number of chars allowed for time range of subtitle" />
                                        <ReactTooltip
                                            effect="solid"
                                            type='info'
                                            multiline="true"
                                        />
                                        <NumericInput
                                            className="form-control"
                                            value={readingSpeed}
                                            onChange={(input) =>
                                                dispatch(changeReadingSpeed(input))
                                            }
                                        />
                                    </MenuItem>
                                </SubMenu>

                                <SubMenu
                                    title="Translation settings"
                                    icon={<AiOutlineTranslation />}
                                >
                                    {/* <MenuItem>
                    Speech-to-text
                        <BootstrapSwitchButton
                            checked={speechToText}
                            onlabel="ON"
                            offlabel="OFF"
                            size="sm"
                            onChange={() => 
                                dispatch(toggleSpeechToText(!speechToText))
                            }>
                        </BootstrapSwitchButton>
                    </MenuItem> */}
                                    {/* <MenuItem>
                        Spell-checker<BsInfoCircle data-tip="Automaticaly check for phrases and dictionary words" />
                        <BootstrapSwitchButton
                            checked={spellChecker}
                            onlabel="ON"
                            offlabel="OFF"
                            size="sm"
                            onChange={() => dispatch(toggleSpellChecker(!spellChecker))}>
                        </BootstrapSwitchButton>
                    </MenuItem> */}
                                    <MenuItem>
                                        Punctation
                                        <BootstrapSwitchButton
                                            checked={punctation}
                                            onlabel="ON"
                                            offlabel="OFF"
                                            size="sm"
                                            onChange={() => {
                                                socket.emit('togglePunctation', !punctation)
                                                dispatch(togglePunctation(!punctation))
                                            }}>
                                        </BootstrapSwitchButton>
                                    </MenuItem>
                                    <MenuItem>
                                        GVT Instant
                                        <BootstrapSwitchButton
                                            checked={gvtInstant}
                                            onlabel="ON"
                                            offlabel="OFF"
                                            size="sm"
                                            onChange={() => {
                                                socket.emit('setGvtInstant', !gvtInstant)
                                                dispatch(toggleGvtInstant(!gvtInstant, true))
                                            }}>
                                        </BootstrapSwitchButton>
                                    </MenuItem>
                                    <MenuItem>
                                        Push/Hold
                                        <BootstrapSwitchButton
                                            checked={pushHold}
                                            onlabel="Hold"
                                            offlabel="Push"
                                            size="sm"
                                            onChange={() =>
                                                dispatch(togglePushHold(!pushHold))
                                            }>
                                        </BootstrapSwitchButton>
                                    </MenuItem>
                                    <MenuItem>
                                        Autosave
                                        <BootstrapSwitchButton
                                            checked={autoSave}
                                            onlabel="ON"
                                            offlabel="OFF"
                                            size="sm"
                                            onChange={() =>
                                                dispatch(toggleAutoSave(!autoSave))
                                            }>
                                        </BootstrapSwitchButton>
                                    </MenuItem>
                                    <MenuItem>
                                        Phrases
                                        <BootstrapSwitchButton
                                            checked={highlightPhrase}
                                            onlabel="ON"
                                            offlabel="OFF"
                                            size="sm"
                                            onChange={() =>
                                                dispatch(toggleHighlightPhrase(!highlightPhrase))
                                            }>
                                        </BootstrapSwitchButton>
                                    </MenuItem>
                                    <MenuItem>
                                        Words
                                        <BootstrapSwitchButton
                                            className="btn-settings-right"
                                            checked={highlightProblematicWords}
                                            onlabel="ON"
                                            offlabel="OFF"
                                            size="sm"
                                            onChange={() =>
                                                dispatch(toggleHighlightProblematicWords(!highlightProblematicWords))
                                            }>
                                        </BootstrapSwitchButton>
                                    </MenuItem>
                                    <MenuItem>
                                        Auto record
                                        <BootstrapSwitchButton
                                            checked={autoRecord}
                                            onlabel="ON"
                                            offlabel="OFF"
                                            size="sm"
                                            onChange={() => {
                                                dispatch(toggleAutoRecord(!autoRecord))
                                            }}>
                                        </BootstrapSwitchButton>
                                    </MenuItem>
                                    <MenuItem>
                                        GVT Language
                                        <Form.Group as={Row}>
                                            <Col sm="12">
                                                <Form.Control as={Col} value={language.lang} as="select" onChange={(select) => {
                                                    socket.emit('languageChange', select.target.value)
                                                    dispatch(changeLanguage(select.target.value))
                                                }
                                                }>
                                                    {languages.map(language => {
                                                        return <option key={language.id}>{language.language}</option>
                                                    })}
                                                </Form.Control>
                                            </Col>
                                        </Form.Group>
                                    </MenuItem>
                                </SubMenu>
                            </Menu>
                        </ProSidebar>
                    </Fade>
                </div>

            }
            {!isMobile &&
                <ProSidebar collapsed={sidebarCollapsed} >
                    <ReactTooltip
                        effect="solid"
                        type='info'
                        multiline="true"
                    />
                    <SidebarHeader>
                        Settings
                    </SidebarHeader>
                    {sidebarCollapsed ?
                        (
                            <Button className="expand-collpase-btn" size="sm" variant="light" onClick={() => toggleSidebar()}>Expand  <BiExpand /></Button>
                            // <span onClick={() => toggleSidebar()}> 
                            //     Expand

                            // </span>
                        ) : (
                            <Button className="expand-collpase-btn" size="sm" variant="light" onClick={() => toggleSidebar()}>Colapse  <BiCollapse /></Button>
                            // <span onClick={() => toggleSidebar()}>
                            //     Collapse
                            //     <BiCollapse/>
                            // </span>
                        )
                    }
                    <Menu iconShape="square">
                        <SubMenu
                            title="Video settings"
                            icon={<AiOutlineVideoCamera />}
                        >
                            <MenuItem>
                                Coming soon...
                                {/* Source Title
                        <BootstrapSwitchButton
                            checked={sourceTitle}
                            onlabel="ON"
                            offlabel="OFF"
                            size="sm"
                            onChange={() => dispatch(toggleSourceTitle(!sourceTitle))}>
                        </BootstrapSwitchButton>
                    </MenuItem>
                    <MenuItem>
                    Edit Title
                        <BootstrapSwitchButton
                            checked={editTitle}
                            onlabel="ON"
                            offlabel="OFF"
                            size="sm"
                            onChange={() => dispatch(toggleEditTitle(!editTitle))}
                        >
                        </BootstrapSwitchButton> */}
                            </MenuItem>
                        </SubMenu>

                        <SubMenu
                            title="Font settings"
                            icon={<AiFillSetting />}
                        >
                            <MenuItem>
                                Title Font Size
                                <NumericInput
                                    className="form-control"
                                    value={titleFontSize}
                                    onChange={(input) =>
                                        dispatch(changeTitleFontSize(input))
                                    }
                                />
                            </MenuItem>
                            <MenuItem>
                                Edit Font Size
                                <NumericInput
                                    className="form-control"
                                    value={editFontSize}
                                    onChange={(input) =>
                                        dispatch(changeEditFontSize(input))
                                    }
                                />
                            </MenuItem>
                            <MenuItem>
                                Row Length Value
                                <NumericInput
                                    className="form-control"
                                    value={rowLengthValue}
                                    onChange={(input) =>
                                        dispatch(changeRowLengthValue(input))
                                    }
                                />
                            </MenuItem>
                            <MenuItem>
                                Reading speed <BsInfoCircle data-tip="Number of chars allowed for time range of subtitle" />
                                <ReactTooltip
                                    effect="solid"
                                    type='info'
                                    multiline="true"
                                />
                                <NumericInput
                                    className="form-control"
                                    value={readingSpeed}
                                    onChange={(input) =>
                                        dispatch(changeReadingSpeed(input))
                                    }
                                />
                            </MenuItem>
                        </SubMenu>

                        <SubMenu
                            title="Translation settings"
                            icon={<AiOutlineTranslation />}
                        >
                            {/* <MenuItem>
                    Speech-to-text
                        <BootstrapSwitchButton
                            checked={speechToText}
                            onlabel="ON"
                            offlabel="OFF"
                            size="sm"
                            onChange={() => 
                                dispatch(toggleSpeechToText(!speechToText))
                            }>
                        </BootstrapSwitchButton>
                    </MenuItem> */}
                            {/* <MenuItem>
                        Spell-checker<BsInfoCircle data-tip="Automaticaly check for phrases and dictionary words" />
                        <BootstrapSwitchButton
                            checked={spellChecker}
                            onlabel="ON"
                            offlabel="OFF"
                            size="sm"
                            onChange={() => dispatch(toggleSpellChecker(!spellChecker))}>
                        </BootstrapSwitchButton>
                    </MenuItem> */}
                            <MenuItem>
                                Punctation
                                <BootstrapSwitchButton
                                    checked={punctation}
                                    onlabel="ON"
                                    offlabel="OFF"
                                    size="sm"
                                    onChange={() => {
                                        socket.emit('togglePunctation', !punctation)
                                        dispatch(togglePunctation(!punctation))
                                    }}>
                                </BootstrapSwitchButton>
                            </MenuItem>
                            <MenuItem>
                                GVT Instant
                                <BootstrapSwitchButton
                                    checked={gvtInstant}
                                    onlabel="ON"
                                    offlabel="OFF"
                                    size="sm"
                                    onChange={() => {
                                        socket.emit('setGvtInstant', !gvtInstant)
                                        dispatch(toggleGvtInstant(!gvtInstant, true))
                                    }}>
                                </BootstrapSwitchButton>
                            </MenuItem>
                            <MenuItem>
                                Push/Hold
                                <BootstrapSwitchButton
                                    checked={pushHold}
                                    onlabel="Hold"
                                    offlabel="Push"
                                    size="sm"
                                    onChange={() =>
                                        dispatch(togglePushHold(!pushHold))
                                    }>
                                </BootstrapSwitchButton>
                            </MenuItem>
                            <MenuItem>
                                Autosave
                                <BootstrapSwitchButton
                                    checked={autoSave}
                                    onlabel="ON"
                                    offlabel="OFF"
                                    size="sm"
                                    onChange={() =>
                                        dispatch(toggleAutoSave(!autoSave))
                                    }>
                                </BootstrapSwitchButton>
                            </MenuItem>
                            <MenuItem>
                                Phrases
                                <BootstrapSwitchButton
                                    checked={highlightPhrase}
                                    onlabel="ON"
                                    offlabel="OFF"
                                    size="sm"
                                    onChange={() =>
                                        dispatch(toggleHighlightPhrase(!highlightPhrase))
                                    }>
                                </BootstrapSwitchButton>
                            </MenuItem>
                            <MenuItem>
                                Words
                                <BootstrapSwitchButton
                                    className="btn-settings-right"
                                    checked={highlightProblematicWords}
                                    onlabel="ON"
                                    offlabel="OFF"
                                    size="sm"
                                    onChange={() =>
                                        dispatch(toggleHighlightProblematicWords(!highlightProblematicWords))
                                    }>
                                </BootstrapSwitchButton>
                            </MenuItem>
                            <MenuItem>
                                Auto record
                                <BootstrapSwitchButton
                                    checked={autoRecord}
                                    onlabel="ON"
                                    offlabel="OFF"
                                    size="sm"
                                    onChange={() => {
                                        dispatch(toggleAutoRecord(!autoRecord))
                                    }}>
                                </BootstrapSwitchButton>
                            </MenuItem>
                            <MenuItem>
                                GVT Language
                                <Form.Group as={Row}>
                                    <Col sm="12">
                                        <Form.Control as={Col} value={language.lang} as="select" onChange={(select) => {
                                            socket.emit('languageChange', select.target.value)
                                            dispatch(changeLanguage(select.target.value))
                                        }
                                        }>
                                            {languages.map(language => {
                                                return <option key={language.id}>{language.language}</option>
                                            })}
                                        </Form.Control>
                                    </Col>
                                </Form.Group>
                            </MenuItem>
                        </SubMenu>
                    </Menu>
                </ProSidebar>
            }
        </>
    )
}

export default HomeSettings
