import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { MDBDataTable } from 'mdbreact'
import { changeUserStatus, getUsers, deleteUser, changeUserPassword, changeUserRole } from '../../actions/adminActions'
import { Button, Form, Col, Fade } from 'react-bootstrap'
import Swal from 'sweetalert2'
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar'
import './adminPanel.css'
import ModalRegistration from '../registrationPage/registrationPage'
import UserGroups from './userGroups/userGroups'
import AdminAddProject from './adminAddProject/adminAddProject'
import AdminProjectList from './adminProjectList/adminProjectList'
import {isMobile} from 'react-device-detect'

const AdminPanel = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const theme = useSelector(state => state.settingsReducer.theme)
    const isAdmin = useSelector(state => state.authorizationReducer.isAdmin)
    const showSidebar = useSelector(state => state.settingsReducer.showSidebar)
    const [showComponent, setShowComponent] = useState('edit-users')
    const [showRegistration, setShowRegistration] = useState(false)
    const [data, setData] = useState({
        columns: [
            {
                label: 'ISV code',
                field: 'username',
            },
            {
                label: "Password",
                field: 'password',

            },
            {
                label: 'Status',
                field: 'status',
            },
            {
                label: 'Delete',
                field: 'btnDelete',
            },
            {
                label: 'Role',
                field: 'btnRole'
            }
        ],
        rows: []
    })
    const [editProject, setEditProject] = useState("")


    const loadUsers = () => {
        dispatch(getUsers())
            .then(res => {
                let usersList = res.users
                usersList.map((user, i) => {
                    user.password =
                        <div className="password-row">
                            <Form.Control size="sm" type="password" placeholder="Password" name="new-password" id={i} autocomplete="new-password" />
                            <Button size="sm" onClick={() => changePassword(user, i)}>Change</Button>
                        </div>
                    user.btnRole =
                        <Button
                            className="table-button"
                            color="default"
                            size="sm"
                            variant={user.isAdmin ? "danger" : "success"}
                            onClick={() => changeRole(user._id, !user.isAdmin)}
                        >
                            {
                                user.isAdmin == true ? "Remove admin" : "Give admin"
                            }
                        </Button>
                    user.btnDelete =
                        <Button
                            className="table-button"
                            variant="danger"
                            color="default"
                            size="sm"
                            onClick={() => removeUser(user._id)}
                        >Delete</Button>
                    if (user.status == "enabled") {
                        return user.status =
                            <Button
                                className="table-button"
                                size="sm"
                                variant="danger"
                                color="default" size="sm"
                                onClick={() => changeStatus(user._id, "blocked")}
                            >Disable</Button>
                    } else {
                        return user.status =
                            <Button
                                className="table-button"
                                color="default"
                                variant="success"
                                size="sm"
                                onClick={() => changeStatus(user._id, "enabled")}
                            >Enable</Button>
                    }
                })
                setData({
                    ...data,
                    rows: usersList
                })
            })
            .catch(err => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: err.msg,
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }

    const changePassword = (user, row) => {
        let newPassword = document.getElementById(row).value
        dispatch(changeUserPassword(user._id, newPassword))
            .then(res => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: res,
                    showConfirmButton: false,
                    timer: 1500
                })
                document.getElementById(row).value = ""
            })
            .catch(err => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: err,
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }

    const changeStatus = (user_id, status) => {
        dispatch(changeUserStatus(user_id, status))
            .then(res => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: res.msg,
                    showConfirmButton: false,
                    timer: 1500,
                })
                loadUsers()
            })
            .catch(err => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: err,
                    showConfirmButton: false,
                    timer: 1500,
                })
            })
    }

    const changeRole = (user_id, status) => {
        dispatch(changeUserRole(user_id, status))
            .then(res => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: res.msg,
                    showConfirmButton: false,
                    timer: 1500,
                })
                loadUsers()
            })
            .catch(err => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: err,
                    showConfirmButton: false,
                    timer: 1500,
                })
            })
    }

    const removeUser = (user_id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(res => {
            if (res.isConfirmed) {
                dispatch(deleteUser(user_id))
                    .then(res => {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: res.msg,
                            showConfirmButton: false,
                            timer: 1500,
                        })
                        loadUsers()
                    })
                    .catch(err => {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: err.msg,
                            showConfirmButton: false,
                            timer: 1500,
                        })
                    })
            }
        })
    }

    const setAutoCompleteOff = () => {
        document.addEventListener('DOMContentLoaded', () => {
            document.querySelectorAll('.form-control-sm').forEach(e => e.setAttribute('autocomplete', 'off'))
        })
    }

    useEffect(() => {
        if(showComponent == "edit-user") {
        document.querySelector('#root > div:nth-child(2) > div > div.admin-div-users > div > div > div:nth-child(1) > div:nth-child(2) > div > input').setAttribute('autocomplete', 'off')
        }
        console.log(history?.location?.state)
        if (history?.location?.state?.showProjects) {
            setShowComponent('list-project')
            history.location.state.showProjects = false
        } else {
            if (!isAdmin) {
                history.push('/projects')
            } else {
                console.log("LOADING USERS")
                loadUsers()
            }
        }
        // setAutoCompleteOff()
    }, [showComponent])

    return (
        
        <div className="admin-div">
            {isMobile ?
            <div className="sidebar" style={{ display: "inline-block", position:"fixed", zIndex:"200" }}>
                <Fade in={showSidebar}>
                <ProSidebar
                    collapsed={false}
                >
                    <Menu iconShape="square">
                        {/* <MenuItem><Button onClick={(() => setShowModalAddProject(true))}>New +</Button></MenuItem> */}
                        {/* <SubMenu title="Components"> */}
                        {/* <MenuItem onClick={() => {
                        setShowUserTable(true)
                        // setShowProjects(MY_PROJECTS)
                        // setIsEditing(false)
                    }}>Users table</MenuItem> */}
                        <Button className="btn-add-user btn-sidebar-admin" variant="primary" size="sm" onClick={() => setShowComponent('edit-users')}>Edit users</Button>
                        <br />
                        <Button className="btn-add-user btn-sidebar-admin" variant="secondary" size="sm" onClick={() => setShowComponent('edit-user-groups')}>User groups</Button>
                        <br />
                        <Button className="btn-add-user btn-sidebar-admin" variant="success" size="sm" onClick={() => {
                            setEditProject("")
                            setShowComponent('admin-project')
                        }}>Add project</Button>
                        <br />
                        <Button className="btn-add-user btn-sidebar-admin" variant="info" size="sm" onClick={() => setShowComponent('list-project')}>Project list</Button>
                        {/* </SubMenu> */}
                    </Menu>
                </ProSidebar>
                </Fade>
            </div>
            :
            <div className="sidebar" style={{ display: "inline-block", position:"fixed", zIndex:"" }}>
                
                <ProSidebar
                    collapsed={false}
                >
                    <Menu iconShape="square">
                        {/* <MenuItem><Button onClick={(() => setShowModalAddProject(true))}>New +</Button></MenuItem> */}
                        {/* <SubMenu title="Components"> */}
                        {/* <MenuItem onClick={() => {
                        setShowUserTable(true)
                        // setShowProjects(MY_PROJECTS)
                        // setIsEditing(false)
                    }}>Users table</MenuItem> */}
                        <Button className="btn-add-user btn-sidebar-admin" variant="primary" size="sm" onClick={() => setShowComponent('edit-users')}>Edit users</Button>
                        <br />
                        <Button className="btn-add-user btn-sidebar-admin" variant="secondary" size="sm" onClick={() => setShowComponent('edit-user-groups')}>User groups</Button>
                        <br />
                        <Button className="btn-add-user btn-sidebar-admin" variant="success" size="sm" onClick={() => {
                            setEditProject("")
                            setShowComponent('admin-project')
                        }}>Add project</Button>
                        <br />
                        <Button className="btn-add-user btn-sidebar-admin" variant="info" size="sm" onClick={() => setShowComponent('list-project')}>Project list</Button>
                        {/* </SubMenu> */}
                    </Menu>
                </ProSidebar>
                
            </div> 
            }
            {
                showComponent == 'edit-users' && (
                    <div className="admin-div-users">
                        <Button className="btn-add-user-admin" variant="success" size="sm" onClick={() => setShowRegistration(true)}>Add user</Button>
                        <div className="table-div">
                            <MDBDataTable
                                sorting={false}
                                responsive
                                striped
                                bordered
                                searching={true}
                                data={data}
                                style={{ color: theme == 'Dark' ? 'white' : '#121212' }}
                            />
                        </div>
                    </div>
                )
            }
            {
                showComponent == 'edit-user-groups' && (
                    <UserGroups
                        theme={theme}
                    />
                )
            }
            {
                showComponent == 'admin-project' && (
                    <AdminAddProject
                        editProject={editProject}
                        theme={theme}
                    />
                )
            }
            {
                showComponent == 'list-project' && (
                    <AdminProjectList
                        setEditProject={setEditProject}
                        setShowComponent={setShowComponent}
                    />
                )
            }

            <ModalRegistration
                showRegistration={showRegistration}
                setShowRegistration={setShowRegistration}
                loadUsers={loadUsers}
            >
            </ModalRegistration>
            
        </div>
    )
}

export default AdminPanel
