import React, { useEffect } from 'react'
import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Modal, Form } from 'react-bootstrap'
import { uploadGvtKey } from '../../../actions/gvtKeyActions'
import Swal from 'sweetalert2'

function ModalGvtSettings({ showGvtSettings, handleCloseGvtSettings }) {
    const dispatch = useDispatch()
    const [currentLoadedGvt, setCurrentLoadedGvt] = useState("current key")
    const [gvtFile, setGvtFile] = useState("")

    const handleClose = () => {
        handleCloseGvtSettings()
    }

    const handleSubmit = () => {
        if(gvtFile) {
            dispatch(uploadGvtKey(gvtFile))
            .then(res => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: res,
                    showConfirmButton: false,
                    timer: 1500,
                })
                handleClose()
            })
            .catch(err => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: err,
                    showConfirmButton: false,
                    timer: 1500,
                })
            })
        }
    }

    return (
        <div>
        <Modal show={showGvtSettings} onHide={() => handleClose()} aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton onClick={() => handleClose()}>
                <Modal.Title>GVT Settings</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate>
                    {/* <Form.Group controlId="formBasicEmail">
                        <Form.Label>Current loaded GVT</Form.Label>
                        <Form.Text>
                            {currentLoadedGvt}
                        </Form.Text>
                        <Form.Control.Feedback></Form.Control.Feedback>
                    </Form.Group> */}
                    <Form.File onChange={(file) => setGvtFile(file.target.files[0])}>
                    </Form.File>
                </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => handleClose()}>Close</Button>
                    <Button variant="primary" onClick={() => handleSubmit()}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ModalGvtSettings
