import React, { useEffect } from 'react'
import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Modal, Form } from 'react-bootstrap'
import { addPhrase, clearMessage, setPhrases } from '../../../actions/wordsActions'
import Swal from 'sweetalert2'

function ModalPhraseWord({ showAddPhraseWord, handleCloseAddPhraseWord, editPhrase, handleEditPhrase }) {
    const dispatch = useDispatch()
    const [phrase, setPhrase] = useState("")
    const [title, setTitle] = useState("Add automatic replacement")

    const handleClose = () => {
        dispatch(clearMessage())
        handleCloseAddPhraseWord()
    }

    const handleSubmit = () => {
        if (editPhrase) {
            handleEditPhrase(phrase)
        } else {
            dispatch(addPhrase(phrase))
                .then(res => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: "Phrase added",
                        showConfirmButton: false,
                        timer: 1000
                    })
                    handleCloseAddPhraseWord()
                })
                .catch(err => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: "Failed to add phrase",
                        showConfirmButton: false,
                        timer: 1000
                    })
                })
        }
    }

    useEffect(() => {
        if (editPhrase) {
            setPhrase(editPhrase)
            setTitle("Edit automatic replacement")
        } else {
            setPhrase("")
            setTitle("Add automatic replacement")
        }
    }, [showAddPhraseWord])

    return (
        <div>
            <Modal show={showAddPhraseWord} onHide={() => handleClose()} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton onClick={() => handleClose()}>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label>Automatic replacement</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                value={phrase}
                                placeholder="Enter phrase..."
                                onChange={(input) => setPhrase(input.target.value)} />
                            <Form.Control.Feedback></Form.Control.Feedback>
                        </Form.Group>
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="primary" onClick={() => handleSubmit()}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ModalPhraseWord
