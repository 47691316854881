import React, { useEffect, useState } from 'react'
import { Modal, Form, Button, Row, Col, Table } from 'react-bootstrap'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2'
import { apiUrl } from '../../../constants'
import { getUsers, editProjectForAll, getUsersForProject, getGroups } from '../../../actions/adminActions'

import { MDBDataTable, MDBBtn } from 'mdbreact'
import './adminAddProject.css'
import { REGISTER_NEW_USER } from '../../../actions/types'
import WysiEditor from './wysiEditor'
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';


const AdminAddProject = ({ theme, editProject }) => {
    const dispatch = useDispatch()
    const [projectName, setProjectName] = useState("") 
    const [projectThumbnail, setProjectThumbnail] = useState("")
    const [videoUrl, setVideoUrl] = useState("")
    const [file, setFile] = useState("")
    const [videoFile, setVideoFile] = useState("")
    const [thumbnailFile, setThumbnailFile] = useState("")
    const [addedUsersList, setAddedUsersList] = useState([])
    const [addEdit, setAddEdit] = useState("add")
    const [createForNew, setCreateForNew] = useState(false);

    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [editorContent, setEditorContent] = useState("")
    const [initialEditorState, setInitialEditorState] = useState(EditorState.createEmpty())

    const [isLoading, setIsLoading] = useState(true)

    const [initialUsers, setInitialUsers] = useState([])

    const [dataToShow, setDataToShow] = useState("users");
    const [data, setData] = useState({
        columns: [
            {
                label: 'ISV code',
                field: 'username',
            },
            {
                label: "Check",
                field: 'checkbox',
                
            },
        ],
        rows: []
    })

    const validateUserInput = () => {
        let validation = true
        let errorMessage = ""
        if(file == "" || file == undefined) {
            validation = false
            errorMessage = "File is not uploaded"
        }
        if(projectName == "") {
            validation = false
            errorMessage = "Project name can't be empty"
        }
        if(addedUsersList.length == 0) {
            validation = false
            errorMessage = "You haven't added users"
        }
        // if((videoUrl == "" || videoUrl == undefined) && (videoFile == "" || videoFile == undefined)) {
        //     validation = false
        //     errorMessage = "You haven't provide video url or file"
        // }
        return [validation, errorMessage]
    }

    const addProject = () => {
        let validation = validateUserInput()
        if(validation[0] == false) {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: validation[1],
                showConfirmButton: false,
                timer: 1500,
            })
            return
        }
        Swal.fire({
            title: 'Are you sure?',
            text: "The project will be added to users",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, add it!'
        }).then(res => {
            if(res.isConfirmed) {
                let validation = validateUserInput()
                if (validation[0] == false) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: validation[1],
                        showConfirmButton: false,
                        timer: 1500,
                    })
                    return
                }
                if(videoFile != "") {
                    let formdata = new FormData()
                    formdata.append('videoFile', videoFile)
                    axios({
                        url: `${apiUrl}/gcs/upload-movie`,
                        method: "POST",
                        data: formdata
                    })
                        .then(res => {
                            console.log("RES: ", res)
                            let videoUrlUploaded = res.data.url
                            let formdata = new FormData()
                            formdata.append('file', file)
                            formdata.append('thumbFile', thumbnailFile)
                            formdata.append('projectName', projectName)
                            formdata.append('projectThumbnail', projectThumbnail)
                            formdata.append('videoUrl', videoUrlUploaded)
                            formdata.append('usersList', JSON.stringify(addedUsersList))
                            formdata.append('projectReadme', JSON.stringify(convertToRaw(editorState.getCurrentContent())))
                            formdata.append('createForNew', createForNew)
                            axios({
                                url: `${apiUrl}/admin/add_project_multiple_users`,
                                method: "POST",
                                data: formdata
                            })
                            .then(res => {
                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'success',
                                    title: res.data.msg,
                                    showConfirmButton: false,
                                    timer: 1500
                                })
                                setAddedUsersList([])
                                setProjectName("")
                                setProjectThumbnail("")
                                setVideoUrl("")
                                setCreateForNew(false);
                            })
                            .catch(err => {
                                console.log(err)
                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'error',
                                    title: err?.response?.data?.msg ?? "Server error!",
                                    showConfirmButton: false,
                                    timer: 2000
                                })
                            })
                        })
                        .catch(err => {
                            Swal.fire({
                                position: 'top-end',
                        icon: 'error',
                        title: "Failed to upload video to Google Cloud",
                        showConfirmButton: false,
                        timer: 2000
                            })
                        })
                } else {
                    let formdata = new FormData()
                    formdata.append('file', file)
                    formdata.append('thumbFile', thumbnailFile)
                    formdata.append('projectName', projectName)
                    formdata.append('projectThumbnail', projectThumbnail)
                    formdata.append('videoUrl', videoUrl)
                    formdata.append('usersList', JSON.stringify(addedUsersList))
                    formdata.append('projectReadme', JSON.stringify(convertToRaw(editorState.getCurrentContent())))
                    formdata.append('createForNew', createForNew)
                    axios({
                        url: `${apiUrl}/admin/add_project_multiple_users`,
                        method: "POST",
                        data: formdata
                    })
                    .then(res => {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: res.data.msg,
                            showConfirmButton: false,
                            timer: 1500
                        })
                        setAddedUsersList([])
                        setProjectName("")
                        setProjectThumbnail("")
                        setVideoUrl("")
                        setEditorState("")
                        setCreateForNew(false);
                    })
                    .catch(err => {
                        console.log(err)
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: err?.response?.data?.msg ?? "Server error!",
                            showConfirmButton: false,
                            timer: 2000
                        })
                    })
                }
            }
        })
    }

    const loadGroups = () => {
        return new Promise((resolve) => {
            dispatch(getGroups())
                .then(res => {
                    let groupsList = res.groups
                    groupsList.map((group, i) => {
                        group.username = group.group_name + " [" + group.group_users.length + " users]";
                        group.checkbox =
                        <Button 
                            size="sm"
                            onClick={() => addGroupToProject(group)} key={i}>Add</Button>
                        
                    })
                    setData({...data,
                        rows: groupsList
                    })
                })
                .catch(err => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: err.msg,
                        showConfirmButton: false,
                        timer: 1500
                    })
                })
                .finally(() => {
                    resolve();
                })
        })
    }

    const addGroupToProject = (group) => {
        let tempUserList = group.group_users;

        setAddedUsersList(prevList => {
            let tempList = [...prevList, ...tempUserList];
            let ids = tempList.map(user => user._id);
            let filtered = tempList.filter(({_id}, index) => !ids.includes(_id, index + 1));
            return (filtered);
        })
    }

    const addUserToProject = (user) => {
        if(!user.checkbox) {
            user = {...user,
                checkbox: <Button size="sm" onClick={() => addUserToProject(user)} >Add</Button>
            }
        }
        setAddedUsersList(prevList => [...prevList, user])
        setData(prevData => ({...prevData,
                rows: prevData.rows.filter(item => {
                    if(item._id != user._id) {
                        return item
                    }
                })
            })
        )
    }

    const removeUserFromList = (user) => {
        let newList = addedUsersList.filter(item => {
            if(item._id != user._id) {
                return item
            }
        })
        setAddedUsersList(newList)
        if (dataToShow === "users") {
            if(!user.checkbox) {
                user = {...user,
                    checkbox: <Button size="sm" onClick={() => addUserToProject(user)}>Add</Button>
                }
            }
            
            setData({...data,
                rows: data.rows.concat(user)
            })
        }
    }

    const loadUsers = () => {
        return new Promise((resolve, reject) => {
            dispatch(getUsers())
            .then(res => {
                let usersList = res.users
                usersList.map((user, i) => {
                    user.checkbox =
                    <Button 
                        size="sm"
                        onClick={() => addUserToProject(user)} key={i}>Add</Button>
                    
                })
                setData({...data,
                    rows: usersList.filter(user1 => !addedUsersList?.some(user2 => (user1._id === user2._id)))
                })
                resolve()
            })
            .catch(err => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: err.msg,
                    showConfirmButton: false,
                    timer: 1500
                })
                reject();
            })
        })
    }

    const editProjectAdmin = () => {
        let usersToRemove = []
        let usersToAdd = []
        let usersToChange = []
        if(file || thumbnailFile || editProject.subtitle_name != projectName || editProject.videoUrl != videoUrl || editorState != initialEditorState) {
            addedUsersList.forEach(user => {
                const found = initialUsers.some(el => el.owner.username === user.username);
                if(!found) {
                    console.log("ADD PROJECT TO: ", user.username)
                    usersToAdd.push(user)
                }
            })
            console.log("_")
            initialUsers.forEach(user => {
                const found = addedUsersList.some(el => el.username === user.owner.username);
                if(found) {
                    console.log("EDIT PROJECT FOR: ", user.owner.username)
                    usersToChange.push(user.owner)
                } else {
                    console.log("DELETE PROJECT FOR: ", user.owner.username)
                    usersToRemove.push(user.owner)
                }
            })
        } else {
            addedUsersList.forEach(user => {
                const found = initialUsers.some(el => el.owner.username === user.username);
                if(!found) {
                    console.log("ADD PROJECT TO: ", user.username)
                    usersToAdd.push(user)
                }
            })
            initialUsers.forEach(user => {
                const found = addedUsersList.some(el => el.username === user.owner.username);
                if(!found) {
                    console.log("DELETE PROJECT FOR: ", user.owner.username)
                    usersToRemove.push(user.owner)
                }
            })
        }
        console.log("USERS TO REMOVE: ", usersToRemove)
        console.log("USERS TO ADD: ", usersToAdd)
        console.log("USERS TO CHANGE: ", usersToChange)
        let wysi = JSON.stringify(convertToRaw(editorState.getCurrentContent()))
        dispatch(editProjectForAll(usersToRemove, usersToChange, usersToAdd, projectName, videoUrl, editProject, file, wysi, thumbnailFile, videoFile, createForNew))
        // addedUsersList.forEach(user => {
        //     const found = initialUsers.some(el => el.owner.username === user.username);
        //     if(!found) {
        //         console.log("ADD PROJECT TO: ", user.username)
        //     }
        // })
        // console.log("_")
        // initialUsers.forEach(user => {
        //     const found = addedUsersList.some(el => el.username === user.owner.username);
        //     if(found) {
        //         console.log("EDIT PROJECT FOR: ", user.owner.username)
        //     } else {
        //         console.log("DELETE PROJECT FOR: ", user.owner.username)
        //     }
        // })
    }

    useEffect(() => {
        loadUsers()
        .then(() => {
                if(editProject) {
                    setIsLoading(true)
                    setCreateForNew(editProject.createForNew)
                    setProjectName(editProject.subtitle_name)
                    setVideoUrl(editProject.videoUrl)
                    editProject.readMe && setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(editProject.readMe))))
                    editProject.readMe && setInitialEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(editProject.readMe))))
                    dispatch(getUsersForProject(editProject.subtitle_name))
                    .then(res => {
                        console.log(res)
                        setInitialUsers(res.users)
                        res.users.forEach((data, i) => {
                            let user = {...data.owner,
                                checkbox: <Button size="sm" onClick={() => addUserToProject(data.owner)} key={i}>Add</Button>
                            }
                            console.log(user)
                            addUserToProject(user)
                        })
                        setIsLoading(false)
                    })
                } else {
                    setProjectName("")
                    setVideoUrl("")
                    setAddedUsersList([])
                    setIsLoading(false)
                    setCreateForNew(false);
                }
            }
        )
        
    }, [editProject])

    useEffect(() => {
        if (dataToShow === "users") {
            loadUsers();
        } else {
            loadGroups();
        }
    }, [dataToShow])

    useEffect(() => {
        console.log("new", createForNew)
    }, [createForNew])

    if(isLoading) {
        return <div className="spinner"></div>
    }

    return (
        <div className="admin-project-add">
            
            <Row>
                
                <Col sm="true">
                    <Form noValidate className="add-project-admin-form" validated={false}>
                        <Form.Group controlId="formBasic">
                            <Form.Label><h5>Project name</h5></Form.Label>
                            <Form.Control 
                            required
                            type="text" 
                            value={projectName}
                            placeholder="Enter project name..." 
                            onChange={(input) => setProjectName(input.target.value)}
                            />
                            <Form.Control.Feedback></Form.Control.Feedback>
                        </Form.Group>

                        <Form.Label>Add video file</Form.Label>
                        <Form.File disabled={videoUrl != ""} onChange={(file) => {
                            if (file.target.files.length == 0) {
                                setVideoFile("")
                            } else {
                                setVideoFile(file.target.files[0])
                            }
                        }
                        }>
                        </Form.File>

                        <Form.Group controlId="formBasic">
                            <Form.Label><h5>Video URL</h5></Form.Label>
                            <Form.Control 
                                disabled={videoFile != ""}
                                type="text" 
                                value={videoUrl}
                                placeholder="Enter video URL..."
                                onChange={(input) => setVideoUrl(input.target.value)}
                            />
                        </Form.Group>

                        {/* <Form.Group controlId="formBasic">
                            <Form.Label>Thumbnail</Form.Label>
                            <Form.Control 
                            type="text" 
                            placeholder="Enter thumbnail (optional)..."
                            onChange={(input) => setProjectThumbnail(input.target.value)}
                            />
                            <Form.Control.Feedback></Form.Control.Feedback>
                        </Form.Group> */}

                        <Form.Label><h5>Upload thumbnail file</h5></Form.Label>
                        <Form.File onChange={(file) => setThumbnailFile(file.target.files[0])}>
                        </Form.File>

                        <Form.Label className="file-upload-admin"><h5>Upload subtitle file</h5></Form.Label>
                        <Form.File onChange={(file) => setFile(file.target.files[0])}>
                        </Form.File>

                        <Form.Group className="formCheckbox">
                            <Form.Control
                                className="checkboxTick"
                                type="checkbox"
                                defaultChecked={createForNew}
                                checked={createForNew}
                                value={createForNew}
                                onChange={(input) => setCreateForNew(input.target.checked)}
                            />
                            <Form.Label><h5 className="checkboxTitle">Create for all new users</h5></Form.Label>
                            <Form.Control.Feedback></Form.Control.Feedback>
                        </Form.Group>
                    </Form>
                </Col>
                <Col sm="true">
                    <Row className="show-groups-button"> 
                        <Button onClick={() => setDataToShow(dataToShow === "users" ? "groups" : "users")}>Show {dataToShow === "users" ? "groups" : "users"}</Button>
                    </Row>
                    <div className="table-div-admin">
                        <MDBDataTable
                            sorting={false}
                            striped
                            bordered
                            searching={true}
                            data={data}
                            style={{color: theme == 'Dark' ? 'white' : '#121212'}}
                        />
                    </div>
                </Col>
                <Col sm="true">
                    <h4>Added list</h4>
                    <Table striped bordered hover className="list-added-table" style={{color: theme == 'Dark' ? 'white' : '#121212'}}>
                        <thead>
                            <th>ISV Code</th>
                            <th>Remove</th>
                        </thead>
                        <tbody>
                            {
                                addedUsersList.length >= 1 ? (
                                    addedUsersList.map(user => (
                                        <tr>
                                            <td>{user.username}</td>
                                            <td><Button variant="danger" size="sm" onClick={() => removeUserFromList(user)}>Remove</Button></td>
                                        </tr>)
                                    )
                                ) : (
                                    <p>You haven't added users yet.</p>
                                )
                            }
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <WysiEditor 
                editorState={editorState}
                setEditorState={setEditorState} 
                setEditorContent={setEditorContent}
            />
            {
                !editProject ? (
                    <Row className="add-project-div"> 
                        <Button onClick={() => addProject()}>Add project</Button>
                    </Row>
                ) : (
                    <Row className="add-project-div"> 
                        <Button onClick={() => editProjectAdmin()}>Edit project</Button>
                    </Row>
                )
            }
        </div>
    )
}

export default AdminAddProject
