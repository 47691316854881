import { SPELL_CHECK_SENTENCE, SPELL_CHECK_WRONG_WORDS } from "../actions/types"

const initialState = {
    wrongSpelledWords: []
}

function spellCheckReducer(state = initialState, action) {
    switch(action.type) {
        case SPELL_CHECK_SENTENCE:
            return {
                ...state,
                wrongSpelledWords: action.payload
            }
        case SPELL_CHECK_WRONG_WORDS:
            return {
                ...state,
                wrongSpelledWords: []
            }
        default:
            return state
    }
}

export default spellCheckReducer