import axios from 'axios'
import { apiUrl } from '../constants'

export const setCurrentProjectForSnapshots = (project) => {
    return {
        type: 'SET_SNAPSHOT_PROJECT',
        payload: project
    }
}

export const getSnapshotsForProject = (projectId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        axios.post(`${apiUrl}/snapshot/get`, {
            projectId
        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err?.response?.data?.msg)
        })
    })
}

export const saveSnapshot = (project, translatedSubtitles, snapshotName) => (dispatch) => {
    return new Promise((resolve, reject) => {
        axios.post(`${apiUrl}/snapshot/new`, {
            project, translatedSubtitles, snapshotName
        }).then(res => {
            resolve(res?.data?.msg)
        }).catch(err => {
            reject(err?.response?.data?.msg)
        })
    })
}

export const deleteSnapshot = (snapshotId) => (dispatch) =>{
    return new Promise((resolve, reject) => {
        axios.post(`${apiUrl}/snapshot/delete`, {
            snapshotId
        }).then(res => {
            resolve(res?.data?.msg)
        }).catch(err => {
            reject(err?.response?.data?.msg)
        })
    })
}

export const editSnapshot = (snapshotId, snapshotName) => (dispatch) =>{
    return new Promise((resolve, reject) => {
        axios.post(`${apiUrl}/snapshot/edit`, {
            snapshotId, snapshotName
        }).then(res => {
            resolve(res?.data?.msg)
        }).catch(err => {
            reject(err?.response?.data?.msg)
        })
    })
}
