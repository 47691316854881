function getSecondsFromTimestamp(timestamp) {
    return new Date(timestamp * 1000).toISOString().substr(11, 12)
}

export function getSrtFromJson(subtitles) {
    let srtSubtitles = "WEBVTT" + "\n"
    subtitles.forEach(subtitle => {
        let start = getSecondsFromTimestamp(subtitle.start)
        let end = getSecondsFromTimestamp(subtitle.end)
        srtSubtitles += subtitle.id + "\n"
        srtSubtitles += `${start} --> ${end}\n`
        srtSubtitles += subtitle.text + "\n"
        srtSubtitles += "\n"
    });
    return srtSubtitles
}