import React, { useEffect }  from 'react'
import { Modal, Button } from 'react-bootstrap'
import {useSelector} from 'react-redux'

function ModalKeyListener() {
    const listenForKeyPress = useSelector(state => state.userSettingsReducer.listeningForKeyPress)
    return (
        <div>
            <Modal show={listenForKeyPress}>
                <Modal.Body>Press any key to save to keybinding.</Modal.Body>
            </Modal>
        </div>
    )
}

export default ModalKeyListener
