import { UPLOAD_GVT_KEY } from "./types"
import axios from 'axios'
import {socket} from "../socket";
import { apiUrl } from "../constants";

export const uploadGvtKey = (file) => dispatch => {
    return new Promise((resolve, reject) => {
        let formData = new FormData()
        formData.append('file', file)
        axios.post(`${apiUrl}/gvt/file-api-key`, formData)
        .then(res => {
            socket.emit('setUsername', localStorage.getItem('username'))
            resolve(res.data.msg)
        })
        .catch(err => {
            reject(err?.response?.data?.msg ?? "Server error!")
        })
    })
}