import axios from "axios"
import { translate } from "react-i18next"
import { combineReducers } from "redux"
import { apiUrl } from "../constants"
import translationSubtitleReducer from "../reducers/translationSubtitleReducer"
import store from '../store'
import { SET_INITIAL_TRANSLATED_SUBTITLES, SET_TRANSLATED_SUBTITLES } from "./types"
import { getAllProjects } from './projectActions'

export const saveProcess = (uploadedSubtitles, translatedSubtitles, subtitleName, projectId = null) => (dispatch, getState) => {
    let currentProject = getState().currentProjectReducer.project;
    if (!currentProject?.isSnapshot) {
        let lastEdited = getState().subtitlePropertiesReducer.lastEdited
        if (translatedSubtitles.length < 1) {
            translatedSubtitles = getState().translatedSubtitlesReducer.translatedSubtitles
            console.log(translatedSubtitles)
        }
        if (uploadedSubtitles.length < 1) {
            uploadedSubtitles = getState().uploadedSubtitlesReducer.uploadedSubtitles
        }
        if (subtitleName == "") {
            subtitleName = getState().subtitlePropertiesReducer.subsTitle
        }
        return new Promise((resolve, reject) => {
            axios.post(`${apiUrl}/process/save_subtitles`, {
                uploadedSubtitles,
                translatedSubtitles,
                subtitleName,
                lastEdited,
                projectId
            })
                .then(res => {
                    dispatch({
                        type: SET_INITIAL_TRANSLATED_SUBTITLES,
                        payload: translatedSubtitles
                    })
                    dispatch(getAllProjects())
                    resolve(res)
                })
                .catch(err => {
                    reject(err)
                })
        })
    }
}

export const checkIfSaved = () => (dispatch, getState) => {
    let subtitles = getState().translatedSubtitlesReducer.translatedSubtitles
    let subtitleName = getState().subtitlePropertiesReducer.subsTitle
    return new Promise((resolve, reject) => {
        axios.post(`${apiUrl}/process/check_process`, {
            subtitles,
            subtitleName
        })
            .then(res => {
                resolve(res.data.status)
            })
            .catch(err => {
                reject(err?.response?.data?.msg)
            })
    })
}