const initialState = {
    chosenSnapshotProject: ""
}

const projectSnapshotsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_SNAPSHOT_PROJECT':
            return {
                ...state,
                chosenSnapshotProject: action.payload
            }
        default:
            return state
    }
}

export default projectSnapshotsReducer
