import { userSettings } from './types'
import axios from 'axios'
import { apiUrl } from '../constants'

export const setVideoPlayPauseKey = (key) => {
    return ({
        type: userSettings.SET_VIDEO_PLAY_PAUSE_KEY,
        payload: key
    })
}

export const setNextSubtitleKey = (key) => {
    return ({
        type: userSettings.SET_NEXT_SUBTITLE_KEY,
        payload: key
    })
}

export const setPreviousSubtitleKey = (key) => {
    return ({
        type: userSettings.SET_PREVIOUS_SUBTITLE_KEY,
        payload: key
    })
}

export const setMicrophoneKey = (key) => {
    return ({
        type: userSettings.SET_MICROPHONE_KEY,
        payload: key
    })
}

export const setListeningForKeyPress = (state) => {
    return ({
        type: userSettings.SET_LISTENING_FOR_KEY,
        payload: state
    })
}

export const setUserSettings = (settings) => {
    return ({
        type: userSettings.SET_USER_SETTINGS,
        payload: settings
    })
}

export const saveUserSettings = (settings) => dispatch => {
    return new Promise((resolve, reject) => {
        let loggedUsername = localStorage.getItem('username');
        const data = {
            settings,
            username: loggedUsername
        }
        axios.post(`${apiUrl}/user-settings`, data)
            .then(res => {
                console.log(res)
                resolve()
            })
            .catch(err => {
                console.log(err)
                reject()
            })
    })
}