import { CLEAR_ERRORS, GET_ERRORS, LOGIN_USER, LOGOUT_USER, userSettings, REGISTER_NEW_USER, SET_ALL_PHRASES, SET_DICT_WORDS, CLEAR_SUBTITLE_PROPERTIES, CLEAR_USER_PROJECTS } from "./types"
import axios from 'axios'

import { socket } from "../socket";
import { apiUrl } from "../constants";

const defaultSettings = {
    videoPlay: 80,
    nextSubtitle: 78,
    previousSubtitle: 66,
    microphoneToggle: 82
}

const setAuthData = (username, isAdmin, moderatedGroups, id) => dispatch => {
    localStorage.setItem('username', username)
    let authData = {
        isLogged: true,
        username: username,
        isAdmin: isAdmin,
        moderatedGroups: moderatedGroups,
        id: id
    }
    dispatch({
        type: LOGIN_USER,
        payload: authData
    })
}

const setSettings = (settings) => dispatch => {
    dispatch({
        type: userSettings.SET_USER_SETTINGS,
        payload: settings
    })
}

const setPhrases = (phrases) => dispatch => {
    dispatch({
        type: SET_ALL_PHRASES,
        payload: phrases
    })
    socket.emit('setPhrases', phrases)
}

const setDictWords = (dictWords) => dispatch => {
    dispatch({
        type: SET_DICT_WORDS,
        payload: dictWords
    })
}

export const clearErrors = () => dispatch => {
    dispatch({
        type: CLEAR_ERRORS
    })
}

const setErrors = (message) => dispatch => {
    dispatch({
        type: GET_ERRORS,
        payload: message
    })
}

const uploadGvtKey = (file, userData) => dispatch => {
    let formData = new FormData();
    formData.append('file', file)
    formData.append('username', userData.username)
    axios.post(
        `${apiUrl}/gvt/file-api-key`,
        formData
    )
        .then(res => {
            socket.emit('setUsername', userData.username)
        })
        .catch(err => {
            console.log(err)
        })
}

export const checkUserCredentials = (userCredentials) => dispatch => {
    return new Promise((resolve, reject) => {
        axios.post(`${apiUrl}/auth/login`, userCredentials)
            .then(res => {
                if (res.data.status == "blocked") {
                    reject("This account is blocked")
                } else {
                    dispatch(loginUser({
                        username: res.data.username,
                        settings: res.data.settings,
                        phrases: res.data.phrases,
                        dict: res.data.dict,
                        isAdmin: res.data.isAdmin,
                        moderatedGroups: res.data.moderatedGroups,
                        id: res.data.id,
                        editSettings: res.data.editSettings,
                    }))
                    resolve()
                }
            })
            .catch(err => {
                reject(err?.response?.data?.msg ?? "Server error!")
            })
    })
}

export const loginUser = (data) => (dispatch, getState) => {
    dispatch({
        type: "SET_LOADING",
    })
    const { username, settings, phrases, dict, isAdmin, editSettings, moderatedGroups, id } = data
    username && dispatch(setAuthData(username, isAdmin, moderatedGroups, id)) 
    settings && dispatch(setSettings(settings))
    phrases && dispatch(setPhrases(phrases))
    dict && dispatch(setDictWords(dict))
    socket.emit('setGvtInstant', getState().settingsReducer.gvtInstant)
    socket.emit('setUsername', username)
    socket.emit('togglePunctation', editSettings.punctation)

    dispatch(clearErrors())
}

export const logoutUser = () => dispatch => {
    return new Promise((resolve, reject) => {
        localStorage.removeItem('username')
        axios.post(`${apiUrl}/auth/logout`, {})
            .then(() => {
                dispatch({
                    type: LOGOUT_USER
                })
                dispatch({
                    type: SET_ALL_PHRASES,
                    payload: []
                })
                dispatch({
                    type: CLEAR_SUBTITLE_PROPERTIES,
                })
                dispatch({
                    type: CLEAR_USER_PROJECTS
                })
                resolve()
            })
            .catch(err => {
                reject("Something went wrong")
            })
    })
}

export const registerUser = (userData) => dispatch => {
    return new Promise((resolve, reject) => {
        let newUserData = {
            username: userData.username,
            email: userData.email,
            password: userData.password,
            settings: defaultSettings
        }
        axios.post(`${apiUrl}/auth/registration`, newUserData)
            .then(res => {
                // newUserData.username && dispatch(setAuthData(newUserData.username))
                // dispatch(clearErrors())
                // let file = userData.file
                // if(file) {
                //     dispatch(uploadGvtKey(file, userData))
                // } else {
                //     console.log("NO FILE FOUND")
                //     return reject("No file found!")
                // }
                resolve("User added")
            })
            .catch(err => {
                err.response?.data?.msg ? reject(err?.response?.data?.msg ?? "Server error!") : reject("Something went wrong :/")
            })
        dispatch({
            type: REGISTER_NEW_USER,
            payload: newUserData
        })
    })
}