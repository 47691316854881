import React, { useState } from 'react'
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const WysiEditor = ({ editorState, setEditorState, setEditorContent, readOnly = false }) => {
    const t = (e) => {
        console.log(editorState)
        console.log(JSON.stringify(editorState))
    }
    return (
        <div className="div-editor-wysi">
            <Editor
                editorState={editorState}
                readOnly={readOnly}
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={e => setEditorState(e)}
                onContentStateChange={e => setEditorContent(e)}
            />
        </div>
    )
}

export default WysiEditor
