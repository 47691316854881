import React, { useEffect } from 'react'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Modal, Form } from 'react-bootstrap'
import { checkUserCredentials } from '../../actions/authActions'
import Swal from 'sweetalert2'

import './loginPage.css'

function ModalLogin() {
    const history = useHistory()
    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const authData = useSelector(state => state.authorizationReducer)
    const error = useSelector(state => state.errorReducer)
    const isLoading = useSelector(state => state.authorizationReducer.isLoading)
    const dispatch = useDispatch()

    const handleSubmit = (e) => {
        e.preventDefault()
        let user = {
            username,
            password
        }
        dispatch(checkUserCredentials(user))
            .then(res => {
                // window.location.reload(false)
            })
            .catch(err => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: err,
                    showConfirmButton: false,
                    timer: 1500,
                })
            })
    }

    useEffect(() => {
        if (authData.isLogged) {
            history.push('/projects')
            
        }
    }, [authData, error])

    if (isLoading) {
        return <div className="spinner"></div>
    }

    return (
        <div className="login-form-div">
            <Form noValidate className="login-form" >
                <Form.Group controlId="formBasicEmail">
                    <Form.Label>ISV Code</Form.Label>
                    <Form.Control
                        required
                        type="email"
                        placeholder="Enter ISV code"
                        onChange={(input) => setUsername(input.target.value)} />
                    <Form.Control.Feedback></Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                        required
                        type="password"
                        placeholder="Password"
                        onChange={(input) => setPassword(input.target.value)}
                    />
                    <Form.Control.Feedback></Form.Control.Feedback>
                </Form.Group>
                <Button type="primary" onClick={(e) => handleSubmit(e)}>Login</Button>
            </Form>
        </div>
    )
}

export default ModalLogin
