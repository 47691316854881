import { LOGIN_USER, LOGOUT_USER } from "../actions/types"

const initialState = {
    isLogged: false,
    username: {},
    isAdmin: false,
    isLoading: true,
    moderatedGroups: [],
    id: null
}

function authorizationReducer(state = initialState, action) {
    switch (action.type) {
        case "SET_LOADING":
            return {
                ...state,
                isLoading: true
            }
        case "STOP_LOADING":
            return {
                ...state,
                isLoading: false
            }
        case LOGIN_USER:
            localStorage.setItem('username', action.payload.username);
            return {
                ...state,
                isLogged: action.payload.isLogged,
                username: action.payload.username,
                isAdmin: action.payload.isAdmin,
                isLoading: false,
                moderatedGroups: action.payload.moderatedGroups,
                id: action.payload.id
            }
        case LOGOUT_USER:
            return {
                ...state,
                isLogged: false,
                username: {},
                isAdmin: false,
                isLoading: false
            }
        default:
            return state
    }
}

export default authorizationReducer