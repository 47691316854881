import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import UploadedSubtitleCell from './subtitleRow/uploadedSubtitleCell'
import TranslatedSubtitleCell from './subtitleRow/translatedSubtitleCell'
import "./subtitles.css"
import { updateTranslatedSubtitles } from '../../actions/translatedSubtitlesActions'
import SubtitleComment from './subtitleRow/subtitleComment'

const Subtitles = () => {
    const dispatch = useDispatch()
    const uploadedSubtitles = useSelector(state => state.uploadedSubtitlesReducer.uploadedSubtitles)
    const translatedSubtitles = useSelector(state => state.translatedSubtitlesReducer.translatedSubtitles)
    const shouldCapitalize = (subtitleId) => {
        let punctuations = ["?", ".", "!"];
        let currentSubtitleIndex = translatedSubtitles.findIndex(s => s.id === subtitleId)
        if (!currentSubtitleIndex) {
            return false;
        }
        let prevSubtitle = translatedSubtitles[currentSubtitleIndex - 1];
        if (!prevSubtitle) {
            return false
        }
        const prevSubtitleText = prevSubtitle.text.trim();
        if (punctuations.includes(prevSubtitleText[prevSubtitleText.length - 1])) {
            return true;
        }
        return false;
    }

    return (
        <div className="subtitles-list" id="subtitles-div">
            {
                translatedSubtitles.map((translatedSubtitle, i) => {
                    return (
                        <div className="subtitles-row" key={`row-${translatedSubtitle.id}`}>
                            <UploadedSubtitleCell
                                key={`upl-${uploadedSubtitles[i].id}`}
                                uploadedSubId={uploadedSubtitles[i].id}
                                uploadedSubText={uploadedSubtitles[i].text}
                                uploadedSubStart={uploadedSubtitles[i].start}
                                uploadedSubEnd={uploadedSubtitles[i].end}
                                uploadedSubComment={uploadedSubtitles[i].comment}
                            />
                            <TranslatedSubtitleCell
                                key={`trn-${translatedSubtitle.id}`}
                                translatedSubId={translatedSubtitle.id}
                                translatedSubText={translatedSubtitle.text}
                                translatedSubStart={translatedSubtitle.start}
                                translatedSubEnd={translatedSubtitle.end}
                                uploadedSubText={uploadedSubtitles.find(us => us.id === translatedSubtitle.id)?.text}
                                numberOfSubtitles={uploadedSubtitles.length}
                                subtitleComment={translatedSubtitle.comment}
                                onShouldCapitalize={shouldCapitalize}
                            />
                            {/* <SubtitleComment
                                translatedSubId={translatedSubtitle.id}
                                subtitleComment={translatedSubtitle.comment}
                            /> */}
                        </div>
                    )
                })
            }
        </div>
    )
}

export default Subtitles
