import React from 'react'
import ModalKeyListener from './modalKeyListener'
import './modalSettings.css'
import Swal from 'sweetalert2'
import { useState, useEffect } from 'react'
import { Button, Modal, Form } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import {
    setNextSubtitleKey,
    setPreviousSubtitleKey,
    setVideoPlayPauseKey,
    setMicrophoneKey,
    setListeningForKeyPress,
    saveUserSettings
} from '../../../actions/userSettingsActions'

const keybidingType = {
    VIDEO_TOGGLE: "VIDEO_TOGGLE",
    NEXT_SUBTITLE: "NEXT_SUBTITLE",
    PREVIOUS_SUBTITLE: "PREVIOUS_SUBTITLE",
    MICROPHONE: 'MICROPHONE'
}

function ModalSettings({ showSettings, handleCloseSettings }) {
    // Keybinding
    const dispatch = useDispatch()
    const videoPlayPause = useSelector(state => state.userSettingsReducer.videoPlayPauseKey)
    const nextSubtitle = useSelector(state => state.userSettingsReducer.nextSubtitleKey)
    const previousSubtitle = useSelector(state => state.userSettingsReducer.previousSubtitleKey)
    const microphone = useSelector(state => state.userSettingsReducer.microphoneKey)
    const listenForKeyPress = useSelector(state => state.userSettingsReducer.listeningForKeyPress)
    // const [listenForKeyPress, setListenForKeyPress] = useState(false)
    const [currentKeybiding, setCurrentKeybiding] = useState()


    const saveChangedKeybiding = (key) => {
        dispatch(setListeningForKeyPress(false))
        switch (currentKeybiding) {
            case keybidingType.VIDEO_TOGGLE:
                dispatch(setVideoPlayPauseKey(key.keyCode))
                break;
            case keybidingType.NEXT_SUBTITLE:
                dispatch(setNextSubtitleKey(key.keyCode))
                break;
            case keybidingType.PREVIOUS_SUBTITLE:
                dispatch(setPreviousSubtitleKey(key.keyCode))
                break;
            case keybidingType.MICROPHONE:
                dispatch(setMicrophoneKey(key.keyCode))
                break;
            default:
                console.log("def")
                break;
        }
    }

    const keybindingPressed = (e) => {
        if (listenForKeyPress) {
            saveChangedKeybiding(e)
        }
    }

    const handleSaveSettings = () => {
        dispatch(saveUserSettings({
            videoPlay: videoPlayPause,
            nextSubtitle,
            previousSubtitle,
            microphoneToggle: microphone,
        })).then(() => {
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: "Saved",
                showConfirmButton: false,
                timer: 1500
            })
        }).catch(() => {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "Failed to save",
                showConfirmButton: false,
                timer: 1500
            })
        })
    }

    useEffect(() => {
        window.addEventListener('keydown', keybindingPressed, false)
        return () => {
            window.removeEventListener('keydown', keybindingPressed, false)
        }
    }, [currentKeybiding, listenForKeyPress])

    return (
        <div>
            <ModalKeyListener />
            <Modal show={showSettings} onHide={() => handleCloseSettings()} aria-labelledby="contained-modal-title-vcenter" centered>

                <Modal.Header closeButton onClick={handleCloseSettings}>
                    <Modal.Title>Settings</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <p>Edit settings and keybindings</p>
                    <p>To trigger keybinding press 'CTRL' + defined shortcut</p>
                    <p>Defined shortcuts:</p>
                    <ul>
                        <li className="modal-settings-li">Video play/pause toggle:
                            <Button variant="outline-info" className="modal-btn-shorcut"
                                onClick={() => {
                                    dispatch(setListeningForKeyPress(true))
                                    setCurrentKeybiding(keybidingType.VIDEO_TOGGLE)
                                }
                                }
                            >{String.fromCharCode(videoPlayPause)}
                            </Button>
                        </li>
                        <li className="modal-settings-li">Next subtitle:
                            <Button variant="outline-info" className="modal-btn-shorcut"
                                onClick={() => {
                                    dispatch(setListeningForKeyPress(true))
                                    setCurrentKeybiding(keybidingType.NEXT_SUBTITLE)
                                }
                                }
                            >{String.fromCharCode(nextSubtitle)}
                            </Button>
                        </li>
                        <li className="modal-settings-li">Previous subtitle:
                            <Button variant="outline-info" className="modal-btn-shorcut"
                                onClick={() => {
                                    dispatch(setListeningForKeyPress(true))
                                    setCurrentKeybiding(keybidingType.PREVIOUS_SUBTITLE)
                                }
                                }
                            >{String.fromCharCode(previousSubtitle)}
                            </Button>
                        </li>
                        <li className="modal-settings-li">Microphone toggle:
                            <Button variant="outline-info" className="modal-btn-shorcut"
                                onClick={() => {
                                    dispatch(setListeningForKeyPress(true))
                                    setCurrentKeybiding(keybidingType.MICROPHONE)
                                }
                                }>
                                {String.fromCharCode(microphone)}
                            </Button>
                        </li>
                    </ul>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => {
                        dispatch(setListeningForKeyPress(false))
                        handleCloseSettings()
                    }}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => handleSaveSettings()}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ModalSettings
