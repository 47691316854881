import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Card, Button, Row, Image, Modal } from 'react-bootstrap'
import WysiEditor from '../adminAddProject/wysiEditor'
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';

import { setCurrentProjectForRecordings } from '../../../actions/recordingActions'
import { setUploadedSubtitles } from '../../../actions/uploadedSubtitlesActions'
import { setSubtitleProperties } from '../../../actions/subtitlePropertiesActions'
import { setTranslatedSubtitles } from '../../../actions/translatedSubtitlesActions'
import { setCurrentProject } from '../../../actions/currentProjectAction'
import { setCurrentProjectForSnapshots } from '../../../actions/snapshotActions'

import { deleteUserFromProject, deleteProjectForAll, getProjectsFromAdmin, getUsersForProject } from '../../../actions/adminActions'

import Swal from 'sweetalert2'
import './adminProjectList.css'
import { apiUrl } from '../../../constants'

const AdminProjectList = ({
    setEditProject,
    setShowComponent
}) => {
    const dispatch = useDispatch()
    const history = useHistory()

    const [adminProjects, setAdminProjects] = useState([])
    const [projectUsers, setProjectUsers] = useState([])
    const [showComponentIntern, setShowComponentIntern] = useState("admin-projects")
    const [isLoading, setIsLoading] = useState(false)

    const [showReadMeModal, setShowReadMeModal] = useState(false)
    const [readMeProject, setReadMeProject] = useState("")

    const getProjects = () => {
        setIsLoading(true)
        dispatch(getProjectsFromAdmin())
            .then(res => {
                console.log(res.projects)
                setAdminProjects(res.projects)
                setIsLoading(false)
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false)
            })
    }

    const showUsersOfProject = (subtitle_name) => {
        setIsLoading(true)
        dispatch(getUsersForProject(subtitle_name))
            .then(res => {
                setShowComponentIntern('show-users')
                console.log(res.users)
                setProjectUsers(res.users)
                setIsLoading(false)
            })
            .catch(err => {
                console.log(err)
                setIsLoading(false)
            })
    }

    const showRecordings = (project_id) => {
        dispatch(setCurrentProjectForRecordings(project_id))
        history.push('/recordings')
    }

    const showSnapshots = (projectId) => {
        dispatch(setCurrentProjectForSnapshots(projectId))
        history.push('/snapshots');
    }

    const projectChosen = (project) => {
        dispatch(setUploadedSubtitles(project.uploaded_subtitles))
        dispatch(setTranslatedSubtitles(project.translated_subtitles))
        dispatch(setSubtitleProperties({
            subsTitle: project.subtitle_name,
            videoUrl: project.videoUrl,
            lastEdited: project.lastEdited
        }))
        dispatch(setCurrentProject(project));
        history.push('/')
    }

    const deleteProject = (project) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(res => {
            if (res.isConfirmed) {
                dispatch(deleteProjectForAll(project))
                    .then(res => {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: "Project deleted",
                            showConfirmButton: false,
                            timer: 1500
                        })
                        getProjects()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
        })
    }

    const deleteUserFromProjectHandler = (project) => {
        Swal.fire({
            title: 'Are you sure?',
            text: `You won't be able to revert this! You will remove ${project.owner.username} from ${project.subtitle_name}.`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(res => {
            if (res.isConfirmed) {
                dispatch(deleteUserFromProject(project))
                    .then(res => {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: "User removed from project.",
                            showConfirmButton: false,
                            timer: 1500
                        })
                        showUsersOfProject(project.subtitle_name);
                    })
                    .catch(err => {
                        console.log(err);
                    })
            }
        })
    }

    const editProject = (project) => {
        setEditProject(project)
        setShowComponent('admin-project')
    }

    const readMeHandler = (project) => {
        setShowReadMeModal(true)
        setReadMeProject(project.readMe)
    }

    useEffect(() => {
        getProjects()
    }, [])

    if (isLoading) {
        return <div className="spinner"></div>
    }

    return (
        <div className="project-list-admin">
            <ReadMeModal showReadMeModal={showReadMeModal} setShowReadMeModal={setShowReadMeModal} readMeProject={readMeProject} />
            <div className="project-list-view">
                {
                    showComponentIntern == 'admin-projects' && (
                        adminProjects.length >= 1 ? adminProjects.map(project => {
                            return (
                                <div>
                                    <Card style={{ width: '18rem', height: 'fit-content', color: 'black' }} key={project.subtitle_name}>
                                        {
                                            project.studentFlag && (
                                                <span className="student-cap-icon">🎓</span>
                                            )
                                        }
                                        <Card.Img variant="top" src={`${apiUrl}/${project.thumbnail}`} style={{objectFit: "contain"}} />
                                        <Card.Body>
                                            <Card.Title>{project.subtitle_name}</Card.Title>
                                            <Card.Text>
                                                Created at: {project.createdAt.split('T')[0] + " " + project.createdAt.split('T')[1].slice(0, 8)}
                                            </Card.Text>
                                            <Card.Text>
                                                Updated at: {project.updatedAt.split('T')[0] + " " + project.updatedAt.split('T')[1].slice(0, 8)}
                                            </Card.Text>
                                            <div className="div-card-button-wrapper">
                                                <Button onClick={() => showUsersOfProject(project.subtitle_name)}>View</Button>
                                                <Button variant="warning" onClick={() => readMeHandler(project)}>ReadMe</Button>
                                                <Button variant="danger" onClick={() => deleteProject(project)}>Delete</Button>
                                                <Button variant="success" onClick={() => editProject(project)}>Edit</Button>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                            )
                        }) : (
                            <h3 className="warning-message-admin-project">No projects</h3>
                        )
                    )
                }
            </div>
            {
                showComponentIntern == 'show-users' && (
                    <>
                        <Button className="go-back-button" variant="info" onClick={() => setShowComponentIntern('admin-projects')}>&#8592;</Button>
                        <div className="users-list-view">
                            {
                                projectUsers.length >= 1 ? projectUsers.map(data => {
                                    return (
                                        <div className="admin-single-project-user">
                                            <h3>{data.owner.username}</h3>
                                            <div className="div-card-button-wrapper">
                                                <Button variant="primary" onClick={() => projectChosen(data)}>Load</Button>
                                                <Button variant="info" onClick={() => showRecordings(data._id)}>Recordings</Button>
                                                <Button variant="info" onClick={() => showSnapshots(data._id)}>Snapshots</Button>
                                                <Button variant="danger" onClick={() => deleteUserFromProjectHandler(data)}>Delete</Button>
                                            </div>
                                        </div>
                                    )
                                }) : (
                                    <p>No students on selected project!</p>
                                )
                            }
                        </div>
                    </>
                )
            }

        </div>
    )
}

const ReadMeModal = ({ showReadMeModal, setShowReadMeModal, readMeProject }) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [editorContent, setEditorContent] = useState("")
    useEffect(() => {
        console.log(readMeProject)
        if (readMeProject == "" || readMeProject == undefined) {
            return
        } else {
            setEditorState(EditorState.createWithContent(convertFromRaw(JSON.parse(readMeProject))))
        }
    }, [readMeProject])
    return (
        <div className="modal-read-me">
            <Modal show={showReadMeModal} onHide={() => setShowReadMeModal(false)} className="read-me-modal" backdrop={true}>
                <Modal.Header closeButton onClick={() => setShowReadMeModal(false)}>
                    <Modal.Title>Modal title</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <WysiEditor
                        editorState={editorState}
                        setEditorState={setEditorState}
                        setEditorContent={setEditorContent}
                        readOnly={true}
                    />
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowReadMeModal(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AdminProjectList