import { combineReducers } from 'redux';
import settingsReducer from './settingsReducer';
import translationSubtitleReducer from './translationSubtitleReducer'
import chosenSubtitleReducer from './chosenSubtitleReducer'
import uploadedSubtitlesReducer from './uploadedSubtitlesReducer'
import subtitlePropertiesReducer from './subtitlePropertiesReducer'
import translatedSubtitlesReducer from './translatedSubtitlesReducer'
import userSettingsReducer from './userSettingsReducer'
import spellCheckReducer from './spellCheckReducer'
import authorizationReducer from './authReducer'
import errorReducer from './errorReducer'
import wordReducer from './wordReducer'
import projectReducer from './projectReducer'
import recordingReducers from './recordingReducers'
import snapshotReducer from './snapshotReducer'
import currentProjectReducer from './currentProjectReducer'


export default combineReducers({
	settingsReducer,
	translationSubtitleReducer,
	chosenSubtitleReducer,
	uploadedSubtitlesReducer,
	subtitlePropertiesReducer,
	translatedSubtitlesReducer,
	userSettingsReducer,
	spellCheckReducer,
	authorizationReducer,
	errorReducer,
	wordReducer,
	projectReducer,
	recordingReducers,
	snapshotReducer,
	currentProjectReducer
});
