import { SET_USER_PROJECTS, CLEAR_USER_PROJECTS } from "../actions/types"

const initialState = {
    projects: []
}

function projectReducer(state = initialState, action) {
    switch (action.type) {
        case SET_USER_PROJECTS:
            return {
                ...state,
                projects: action.payload
            }
        case CLEAR_USER_PROJECTS:
            return {
                ...state,
                projects: []
            }
        default:
            return state
    }
}

export default projectReducer