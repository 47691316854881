import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import ReactPlayer from 'react-player'
import AudioRecorder from '../audioRecorder/audioRecorder'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { useSelector } from 'react-redux'
import './videoPlayer.css'
import { setLastEdited } from '../../actions/subtitlePropertiesActions'
import { getSrtFromJson } from '../../helpers/ParserHelper'

const VideoPlayer = () => {
    const dispatch = useDispatch()
    const videoUrl = useSelector(state => state.subtitlePropertiesReducer.videoUrl)
    const subtitleProps = useSelector(state => state.subtitlePropertiesReducer)
    const uploadedSubtitles = useSelector(state => state.uploadedSubtitlesReducer.uploadedSubtitles)
    const [isSeeking, setIsSeeking] = useState(true)
    const player = useRef()

    const videoPlayPauseKey = useSelector(state => state.userSettingsReducer.videoPlayPauseKey)
    const subtitleTimestamp = useSelector(state => state.chosenSubtitleReducer.chosenSubtitle.start)

    const [isPlaying, setIsPlaying] = useState(false)
    const [userUrlInput, setUserUrlInput] = useState("")

    const downHandler = (key) => {
        key.keyCode === videoPlayPauseKey && key.ctrlKey && setIsPlaying(!isPlaying)
    }

    const setVideoUrl = () => {
        let buffered = player.current.player.player.player.buffered
    }

    const setCurrentTime = () => {
        if (subtitleTimestamp != "") {
            player.current.player.player.player.currentTime = subtitleTimestamp;
            setIsSeeking(false)
        }
    }

    const onSeeking = (time) => {
        let sub;
        setIsSeeking(true)
        if (isSeeking) {
            for (var i = 0; i < uploadedSubtitles.length - 2; i++) {
                if (uploadedSubtitles[i].start < time && uploadedSubtitles[i].end > time) {
                    sub = i
                    break
                }
                else if (uploadedSubtitles[i].end < time && uploadedSubtitles[i + 1].start > time) {
                    if ((time - uploadedSubtitles[i].end) < (uploadedSubtitles[i + 1].start - time)) {
                        sub = i
                    } else {
                        sub = i + 1
                    }
                    break;
                }

            }
            if (i > uploadedSubtitles.length - 3) {
                dispatch(setLastEdited(1))
            } else {
                dispatch(setLastEdited(i + 1))
            }
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', downHandler, false)
        return () => {
            window.removeEventListener('keydown', downHandler, false)
        }
    }, [isPlaying, videoPlayPauseKey])

    useEffect(() => {
        setCurrentTime()
        setIsSeeking(false)
    }, [subtitleTimestamp])

    // useEffect(() => {
    //     console.log(subtitleProps.url)
    //     console.log(subtitleProps)
    // }, [subtitleProps])

    return (
        <div style={{ marginTop: '10px', maxWidth:'90%'}} className="video-div">
            <ReactPlayer
                ref={player}
                id="video"
                key={subtitleProps.url}
                controls
                playing={isPlaying}
                width="100%"
                height="auto"
                onSeek={(time) => onSeeking(time)}
                url={videoUrl}
                config={{
                    file: {
                        attributes: {
                            crossOrigin: 'true'
                        },
                        tracks: [
                            { kind: 'subtitles', src: subtitleProps.url, srcLang: subtitleProps.lang, label: "Uploaded", default: true },
                        ]
                    },
                    youtube: {

                    }
                }}
            />
            <div style={{ marginTop: '10px' }}>
                <AudioRecorder
                    isPlaying={isPlaying}
                    setIsPlaying={setIsPlaying}
                    player={player}
                    subtitlePropsUrl={subtitleProps}
                />
            </div>
        </div>
    )
}

export default VideoPlayer
