import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app';
import "bootstrap/dist/css/bootstrap.css";
import store from './store'
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux'

import './i18n'

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <App />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
