import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Row, Col, Table } from 'react-bootstrap';
import { MDBDataTable, MDBBtn } from 'mdbreact';
import Swal from 'sweetalert2';

import { getUsers, getGroups, addGroup, deleteGroup, loadGroupUsers, editGroupUsers, editGroupName, setUserAsGroupModerator, deleteUserGroupModeratorRole, getModeratedGroups } from '../../../actions/adminActions';
import './userGroups.css';

const UserGroups = ({ theme }) => {
    const dispatch = useDispatch();

    const [selectedGroup, setSelectedGroup] = useState();
    const [groups, setGroups] = useState({
        columns: [
            {
                label: 'Group name',
                field: 'text',
            },
            {
                label: "Actions",
                field: 'checkbox',
            },
        ],
        rows: [],
    });
    const [addedUsersList, setAddedUsersList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const loggedUserId = useSelector(state => state.authorizationReducer.id)
    const [userData, setUserData] = useState({
        columns: [
            {
                label: 'ISV code',
                field: 'username',
            },
            {
                label: "Check",
                field: 'checkbox',

            },
        ],
        rows: [],
    });
    const [dataLoading, setDataLoading] = useState(false);
    const authData = useSelector(state => state.authorizationReducer)
    const isAdmin = useSelector(state => state.authorizationReducer.isAdmin)
    const moderatedGroups = useSelector(state => state.authorizationReducer.moderatedGroups)

    const loadUsersForGroup = (groupId) => {
        return new Promise((resolve) =>
            dispatch(loadGroupUsers(groupId))
                .then((res) => {
                    setAddedUsersList(res.users[0].group_users);
                    setUserData(prevUserData => ({
                        ...prevUserData,
                        rows: prevUserData.rows.filter(user1 => !res.users[0].group_users.some(user2 => (user1._id === user2._id)))
                    }));
                })
                .catch((err) => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong, try again!',
                    })
                })
                .finally(() => {
                    resolve();
                })
        );
    }

    const loadUsersForGroupHandler = async (group) => {
        setDataLoading(true);
        setSelectedGroup(group);
        await loadUsers();
        await loadUsersForGroup(group._id);
        setDataLoading(false);
    }

    const editGroupHandler = async (group) => {
        const { value: groupTitle } = await Swal.fire({
            title: 'Name your group below',
            input: 'text',
            inputLabel: 'Group title',
            inputValue: group.group_name,
            inputPlaceholder: 'Group A'
        });
        if (groupTitle) {
            dispatch(editGroupName(group._id, groupTitle))
                .then(() => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Group edited!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    loadGroups();
                })
                .catch(() => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong, try again!',
                    })
                });
        }
    }

    const deleteGroupHandler = (groupId) => {
        dispatch(deleteGroup(groupId))
            .then(() => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Group deleted!',
                    showConfirmButton: false,
                    timer: 1500
                })
                loadGroups();
                setSelectedGroup();
            })
            .catch(() => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong, try again!',
                })
            });
    }

    const loadGroups = () => {
        let getGroupFunction = getGroups()
        if (!isAdmin) {
            getGroupFunction = getModeratedGroups(loggedUserId)
        }
        dispatch(getGroupFunction)
            .then(res => {
                let groupsList = res.groups;
                groupsList.map((group, i) => {
                    group.text = group.group_name;
                    group.checkbox =
                        <>
                            <Button
                                size="sm"
                                onClick={() => loadUsersForGroupHandler(group)} key={"load-button-" + i}
                            >
                                Select
                            </Button>
                            <Button
                                size="sm"
                                className="button-actions"
                                variant="secondary"
                                onClick={() => editGroupHandler(group)} key={"editButton" + i}
                            >
                                Edit title
                            </Button>
                            <Button
                                size="sm"
                                className="button-actions"
                                variant="danger"
                                onClick={() => deleteGroupHandler(group._id)} key={"delete-button-" + i}
                            >
                                Delete
                            </Button>
                        </>
                })
                setGroups({
                    ...groups,
                    rows: groupsList
                })
            })
            .catch(err => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: err.msg,
                    showConfirmButton: false,
                    timer: 1500
                })
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const addNewGroup = async () => {
        const { value: groupTitle } = await Swal.fire({
            title: 'Name your group below',
            input: 'text',
            inputLabel: 'Group title',
            inputPlaceholder: 'Group A'
        });
        if (groupTitle) {
            dispatch(addGroup(groupTitle))
                .then((res) => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Group created!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    loadUsers();
                    setSelectedGroup(res.group);
                    loadGroups();
                })
                .catch(() => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong, try again!',
                    })
                });
        }
    }

    const saveGroupSettings = () => {
        let tempList = addedUsersList.map(user => user._id);
        console.log("USERS:")
        console.log(addedUsersList)
        dispatch(editGroupUsers(selectedGroup, tempList))
            .then(() => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Group user saved!',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
            .catch(() => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong, try again!',
                })
            });
    }

    const loadUsers = () => {
        return new Promise((resolve, reject) => {
            dispatch(getUsers())
                .then(res => {
                    let usersList = res.users
                    usersList.map((user, i) => {
                        user.checkbox =
                            <Button
                                size="sm"
                                onClick={() => addUserToProject(user)} key={i}>Add</Button>

                    })
                    setUserData({
                        ...userData,
                        rows: usersList
                    })
                })
                .catch(err => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: err.msg,
                        showConfirmButton: false,
                        timer: 1500
                    })
                })
                .finally(() => {
                    resolve();
                })
        })
    }

    useEffect(() => {
        loadGroups();
    }, []);

    const addUserToProject = (user) => {
        if (!user.checkbox) {
            user = {
                ...user,
                checkbox: <Button size="sm" onClick={() => addUserToProject(user)}>Add</Button>
            }
        }
        setAddedUsersList(prevList => [...prevList, user]);
        setUserData(prevUserData => ({
            ...prevUserData,
            rows: prevUserData.rows.filter(item => {
                if (item._id != user._id) {
                    return item
                }
            })
        })
        );
    }


    const assignUserAsGroupModerator = (userId, groupId) => {
        setAddedUsersList(prevList => prevList.map(u => {
            if (u._id !== userId) {
                return u
            }
            if (!u.moderated_groups) {
                u.moderated_groups = [groupId]
                return u
            }
            u.moderated_groups.push(groupId)
            return u
        }))
        dispatch(setUserAsGroupModerator(userId, groupId))
            .then(() => {
                console.log("ASSIGNED")
            }
            )
            .catch(err => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: err.msg,
                    showConfirmButton: false,
                    timer: 1500
                })
            })

    }

    const removeUserFromModeratorRole = (userId, groupId) => {
        setAddedUsersList(prevList => prevList.map(u => {
            if (u._id !== userId) {
                return u
            }
            if (!u.moderated_groups || u.moderated_groups.length === 0) {
                return u
            }
            u.moderated_groups = u.moderated_groups.filter(gId => gId !== groupId)
            return u
        }))
        dispatch(deleteUserGroupModeratorRole(userId, groupId))
            .then(() => {
                console.log("REMOVED")
            })
            .catch(err => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: err.msg,
                    showConfirmButton: false,
                    timer: 1500
                })
            })
    }

    const removeUserFromList = (user) => {
        let newList = addedUsersList.filter(item => {
            if (item._id != user._id) {
                return item
            }
        });
        setAddedUsersList(newList);

        if (!user.checkbox) {
            user = {
                ...user,
                checkbox: <Button size="sm" onClick={() => addUserToProject(user)}>Add</Button>
            }
        }
        setUserData({
            ...userData,
            rows: userData.rows.concat(user)
        });
    }

    if (isLoading) {
        return <div className="spinner" />
    }

    return (
        <div className="admin-project-add">
            <Row style={{ overflowX: "hidden" }}>
                <Col sm="true">
                    <div className="table-div-admin">
                        <MDBDataTable
                            sorting={false}
                            striped
                            bordered
                            hover
                            searching={true}
                            data={groups}
                            style={{ color: theme == 'Dark' ? 'white' : '#121212' }}
                        />
                    </div>
                </Col>
                {dataLoading ? <div className="spinner-not-absolute" />
                    : !selectedGroup ? <p className="noUsers"><b>No group selected, please select one.</b></p>
                        : <>
                            <Col sm="true">
                                <div className="table-div-admin">
                                    <MDBDataTable
                                        sorting={false}
                                        striped
                                        bordered
                                        hover
                                        searching={true}
                                        data={userData}
                                        style={{ color: theme == 'Dark' ? 'white' : '#121212' }}
                                    />
                                </div>
                            </Col>
                            <Col sm="true">
                                <h4>Users in {selectedGroup.group_name}</h4>
                                <Table
                                    striped
                                    bordered
                                    hover
                                    className="list-added-table"
                                    style={{ color: theme == 'Dark' ? 'white' : '#121212' }}
                                >
                                    <thead>
                                        <th>ISV Code</th>
                                        <th>Actions</th>
                                    </thead>
                                    <tbody>
                                        {
                                            addedUsersList.length >= 1 ? (
                                                addedUsersList.map(user => {
                                                    return (
                                                        <tr key={user._id + "speak"}>
                                                            <td>{user.username}</td>
                                                            {user._id !== loggedUserId &&
                                                                <td><Button variant="danger" size="sm" onClick={() => removeUserFromList(user)}>Remove</Button></td>
                                                            }
                                                        </tr>
                                                    );
                                                }
                                                )
                                            ) : (
                                                <p className="noUsers">Selected group has no users!</p>
                                            )
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        </>
                }
            </Row>
            <Row className="add-project-div">
                {selectedGroup && <Button variant="success" className="bottom-button" onClick={() => saveGroupSettings()}>Save group - {selectedGroup.group_name}</Button>}
            </Row>
        </div>
    )
}

export default UserGroups
