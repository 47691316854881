import { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import { setSettingsFromDatabase } from './actions/settingsActions';
import Home from './components/home/home';
import LoginPage from './components/loginPage/loginPage'
import RegistrationPage from './components/registrationPage/registrationPage'
import Phrases from './components/dictionaryWords/phrases'
import Projects from './components/projects/projects'
import NavBar from './components/navbar/navbar'
import AdminPanel from './components/adminPanel/adminPanel';
import Recordings from './components/recordings/recordings'
import Snapshots from './components/snapshots/snapshots';
import ModeratorPanel from './components/moderatorPanel/moderatorPanel';

const App = () => {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setSettingsFromDatabase())
    }, [])

    return (
        <>
            <NavBar />
            <div className="main-content">
                <Switch>
                    <Route path="/admin-panel">
                        <AdminPanel />
                    </Route>
                    <Route path="/moderator-panel">
                        <ModeratorPanel />
                    </Route>
                    <Route path="/projects">
                        <Projects />
                    </Route>
                    <Route path="/dict">
                        <Phrases />
                    </Route>
                    <Route path="/login">
                        <LoginPage />
                    </Route>
                    <Route path="/registration">
                        <RegistrationPage />
                    </Route>
                    <Route path="/recordings">
                        <Recordings />
                    </Route>
                    <Route path="/snapshots">
                        <Snapshots />
                    </Route>
                    <Route path="/" exact>
                        <Home />
                    </Route>
                </Switch>
            </div>
        </>
    )
}

export default App;