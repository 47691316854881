import { ADD_PHRASE, CLEAR_MESSAGE, SET_PHRASES, SET_ALL_PHRASES, SET_DICT_WORDS } from "../actions/types"

const initialState = {
    msg: "",
    phrases: [],
    dictWords: []
}

function wordReducer(state = initialState, action) {
    switch(action.type) {
        case ADD_PHRASE:
            return {
                ...state,
                msg: action.payload
            }
        case CLEAR_MESSAGE:
            return {
                ...state,
                msg: ""
            }
        case SET_ALL_PHRASES:
        case SET_PHRASES:
            return {
                ...state,
                phrases: action.payload
            }
        case SET_DICT_WORDS:
            return {
                ...state,
                dictWords: action.payload
            }
        default:
            return state
    }
}

export default wordReducer