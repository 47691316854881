import './progressBar.css'


function ProgressBar({ percentage }) {
    return (
        <div className="progressBarContainer">
            <div
                className="progressBarGreen"
                style={{ width: `${percentage}%` }}
            ></div>
            <div
                className="progressBarGrey"
                style={{ width: `${100 - percentage}%` }}
            ></div>
        </div>
    );
}

export default ProgressBar