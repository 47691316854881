import axios from "axios"
import { apiUrl } from "../constants"
import Swal from 'sweetalert2'

export const getUsers = () => dispatch => {
    return new Promise((resolve, reject) => {
        axios.post(`${apiUrl}/admin/get_users`)
            .then(res => {
                if (res.data.users) {
                    resolve({
                        users: res.data.users
                    })
                }
            })
            .catch(err => {
                reject({ msg: err?.response?.data?.msg ?? "Server error!" })
            })
    })
}

export const changeUserStatus = (user_id, status) => dispatch => {
    console.log("STAUTS: ", status)
    return new Promise((resolve, reject) => {
        axios.post(`${apiUrl}/admin/user_status`, {
            user_id,
            status
        })
            .then(res => {
                resolve({ msg: res.data.msg })
            })
            .catch(err => {
                reject({ msg: err?.response?.data?.msg ?? "Server error!" })
            })
    })
}

export const changeUserRole = (user_id, status) => dispatch => {
    return new Promise((resolve, reject) => {
        axios.post(`${apiUrl}/admin/user_role`, {
            user_id,
            status
        })
            .then(res => {
                resolve({ msg: res.data.msg })
            })
            .catch(err => {
                reject({ msg: err?.response?.data?.msg ?? "Server error!" })
            })
    })
}

export const deleteUser = (user_id) => dispatch => {
    return new Promise((resolve, reject) => {
        axios.post(`${apiUrl}/admin/delete_user`, {
            user_id
        })
            .then(res => {
                resolve({ msg: res.data.msg })
            })
            .catch(err => {
                reject({ msg: err?.response?.data?.msg ?? "Server error!" })
            })
    })
}

export const changeUserPassword = (user_id, password) => dispatch => {
    return new Promise((resolve, reject) => {
        axios.post(`${apiUrl}/admin/change_password`, {
            user_id,
            password
        })
            .then(res => {
                resolve(res.data.msg)
            })
            .catch(err => {
                reject(err?.response?.data?.msg ?? "Server error!")
            })
    })
}

// GET ADMIN PROJECT
export const getProjectsFromAdmin = () => dispatch => {
    return new Promise((resolve, reject) => {
        axios.post(`${apiUrl}/admin/get-projects`)
            .then(res => {
                resolve(res?.data)
            })
            .catch(err => {
                reject(err?.response?.data?.msg)
            })
    })
}

export const getUsersForProject = (subtitle_name) => dispatch => {
    return new Promise((resolve, reject) => {
        axios.post(`${apiUrl}/admin/get-users-project`, {
            subtitle_name
        })
            .then(res => {
                resolve(res?.data)
            })
            .catch(err => {
                reject(err?.response?.data?.msg)
            })
    })
}

export const deleteUserFromProject = (project) => dispatch => {
    return new Promise((resolve, reject) => {
        axios.post(`${apiUrl}/admin/delete-user-from-project`, {
            project_id: project._id
        }).then(res => {
            resolve(res?.data)
        }).catch(err => {
            reject(err?.response?.data?.msg)
        });
    });
}

export const deleteProjectForAll = (project) => dispatch => {
    return new Promise((resolve, reject) => {
        axios.post(`${apiUrl}/admin/delete-project`, {
            subtitle_name: project.subtitle_name,
            video_url: project.videoUrl
        })
            .then(res => {
                resolve(res?.data)
            })
            .catch(err => {
                reject(err?.response?.data?.msg)
            })
    })
}

export const editProjectForAll = (usersToRemove, usersToChange, usersToAdd, subtitle_name, videoUrl, project, file, wysi, thumbnailFile, videoFile, createForNew) => dispatch => {
    if (videoFile == null || videoFile == "" || videoFile == undefined) {
        let formdata = new FormData()
        formdata.append('file', file)
        formdata.append('project_id', project._id)
        formdata.append('project_name', subtitle_name)
        formdata.append('videoUrl', videoUrl)
        formdata.append('usersToRemove', JSON.stringify(usersToRemove))
        formdata.append('usersToChange', JSON.stringify(usersToChange))
        formdata.append('usersToAdd', JSON.stringify(usersToAdd))
        formdata.append('project', JSON.stringify(project))
        formdata.append('projectReadme', wysi)
        formdata.append('thumbnailFile', thumbnailFile)
        formdata.append('createForNew', createForNew || false)
        console.log("EDITING PROJECT ACTION")

        axios({
            url: `${apiUrl}/admin/edit-project`,
            method: "POST",
            data: formdata
        })
            .then(res => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: res.data.msg,
                    showConfirmButton: false,
                    timer: 1500
                })
            })
            .catch(err => {
                console.log(err)
            })

    } else {
        let formdata = new FormData()
        formdata.append('videoFile', videoFile)
        axios({
            url: `${apiUrl}/gcs/upload-movie`,
            method: "POST",
            data: formdata
        })
            .then(res => {
                videoUrl = res.data.url
                let formdata = new FormData()
                formdata.append('file', file)
                formdata.append('project_id', project._id)
                formdata.append('project_name', subtitle_name)
                formdata.append('videoUrl', videoUrl)
                formdata.append('usersToRemove', JSON.stringify(usersToRemove))
                formdata.append('usersToChange', JSON.stringify(usersToChange))
                formdata.append('usersToAdd', JSON.stringify(usersToAdd))
                formdata.append('project', JSON.stringify(project))
                formdata.append('projectReadme', wysi)
                formdata.append('thumbnailFile', thumbnailFile)
                console.log("EDITING PROJECT ACTION")

                axios({
                    url: `${apiUrl}/admin/edit-project`,
                    method: "POST",
                    data: formdata
                })
                    .then(res => {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: res.data.msg,
                            showConfirmButton: false,
                            timer: 1500
                        })
                    })
                    .catch(err => {
                        console.log(err)
                    })
            })
    }
}

// USER GROUPS
export const getGroups = () => dispatch => {
    return new Promise((resolve, reject) => {
        axios.post(`${apiUrl}/userGroups/get_groups`)
            .then(res => {
                if (res.data.group) {
                    resolve({ groups: res.data.group });
                }
            })
            .catch(err => {
                reject({ msg: err?.response?.data?.msg ?? "Server error!" });
            })
    });
}

export const getModeratedGroups = (userId) => dispatch => {
    return new Promise((resolve, reject) => {
        axios.post(`${apiUrl}/userGroups/get_moderated_groups`, {
            user_id: userId
        })
            .then(res => {
                if (res.data.group) {
                    resolve({ groups: res.data.group });
                }
            })
            .catch(err => {
                reject({ msg: err?.response?.data?.msg ?? "Server error!" });
            })
    });
}

export const addGroup = (groupTitle) => dispatch => {
    return new Promise((resolve, reject) => {
        axios.post(`${apiUrl}/userGroups/new_group`, {
            groupTitle
        }).then(res => {
            if (res.data) {
                resolve({ group: res.data.group });
            }
        }).catch(err => {
            reject({ msg: err?.response?.data?.msg ?? "Server error!" });
        });
    });
}

export const deleteGroup = (groupId) => dispatch => {
    return new Promise((resolve, reject) => {
        axios.post(`${apiUrl}/userGroups/delete_group`, {
            groupId
        }).then(res => {
            if (res.data) {
                resolve({ msg: res.data?.msg ?? "Success!" });
            }
        }).catch(err => {
            reject({ msg: err?.response?.data?.msg ?? "Server error!" });
        });
    });
}

export const loadGroupUsers = (groupId) => dispatch => {
    return new Promise((resolve, reject) => {
        axios.post(`${apiUrl}/userGroups/get_group_users`, {
            groupId
        }).then(res => {
            if (res.data.group) {
                resolve({ users: res.data.group });
            }
        }).catch(err => {
            reject({ msg: err?.response?.data?.msg ?? "Server error!" });
        });
    });
}

export const editGroupUsers = (groupId, users) => dispatch => {
    return new Promise((resolve, reject) => {
        axios.post(`${apiUrl}/userGroups/edit_group_users`, {
            groupId, users
        }).then(res => {
            if (res.data) {
                resolve({ msg: res.data?.msg ?? "Success!" });
            }
        }).catch(err => {
            reject({ msg: err?.response?.data?.msg ?? "Server error!" });
        });
    });
}

export const setUserAsGroupModerator = (userId, groupId) => dispatch => {
    return new Promise((resolve, reject) => {
        axios.post(`${apiUrl}/admin/assign_user_as_group_moderator`, {
            group_id: groupId,
            user_id: userId
        }).then(res => {
            if (res.data) {
                resolve({ msg: res.data?.msg ?? "Success!" });
            }
        }).catch(err => {
            reject({ msg: err?.response?.data?.msg ?? "Server error!" });
        });
    })
}

export const deleteUserGroupModeratorRole = (userId, groupId) => dispatch => {
    return new Promise((resolve, reject) => {
        axios.post(`${apiUrl}/admin/remove_user_as_group_moderator`, {
            group_id: groupId,
            user_id: userId
        }).then(res => {
            if (res.data) {
                resolve({ msg: res.data?.msg ?? "Success!" });
            }
        }).catch(err => {
            reject({ msg: err?.response?.data?.msg ?? "Server error!" });
        });
    })
}

export const editGroupName = (groupId, title) => dispatch => {
    return new Promise((resolve, reject) => {
        axios.post(`${apiUrl}/userGroups/edit_group_name`, {
            groupId, title
        }).then(res => {
            if (res.data) {
                resolve({ msg: res.data?.msg ?? "Success!" });
            }
        }).catch(err => {
            reject({ msg: err?.response?.data?.msg ?? "Server error!" });
        });
    });
}
