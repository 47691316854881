const sentenceRegex = /[.!?]$/;

export function shouldHaveCapitalLetter(sentence) {
    return sentenceRegex.test(sentence.trim());
}

export const capitalLettersForNamesInPhrases = (sentence, phrases) => {
    phrases.forEach(phrase => {
        let regex = new RegExp(`\\b${phrase.toLowerCase()}\\b`, "g")
        sentence = sentence.replace(regex, phrase)
    })
    return sentence
}

export const fixQuotes = (inputString) => {
    let outputString = inputString;
    let quoteIndexes = getCharIndexes(inputString, `"`);
    if (quoteIndexes.length <= 0) {
        return removeDoubleSpacesFromString(inputString);
    }
    let length = quoteIndexes.length % 2 === 0 ? quoteIndexes.length : quoteIndexes.length - 1
    for (let i = 0; i < length; i = i + 2) {
        quoteIndexes = getCharIndexes(outputString, `"`);
        const beginningIndex = quoteIndexes[i];
        const endingIndex = quoteIndexes[i + 1];
        let subString = outputString.substring(beginningIndex, endingIndex).replaceAll(`"`, "").trim();
        outputString = outputString.substring(0, beginningIndex) + ` "` + subString + `" ` + outputString.substring(endingIndex + 1)
    }
    if (length < quoteIndexes.length) {
        quoteIndexes = getCharIndexes(outputString, `"`);
        const beginningIndex = quoteIndexes[quoteIndexes.length - 1];
        let subString = outputString.substring(beginningIndex).replaceAll(`"`, "").trim();
        outputString = outputString.substring(0, beginningIndex) + ` "` + subString;
    }
    return removeDoubleSpacesFromString(outputString)
}

export const removeSpacesAfterNewLine = (inputString) => {
    let outputArray = inputString.split("\n");
    for (let i = 1; i < outputArray.length; i++) {
        if (outputArray[i][0] === " ") {
            outputArray[i] = outputArray[i].trimStart();
        }
    }
    return outputArray.join("\n");
}

const removeDoubleSpacesFromString = (inputString) => {
    let outputStringArray = inputString.split("");
    let index = 0;
    let whiteSpaceCounter = 0;
    while (index < outputStringArray.length) {
        if (outputStringArray[index] === " " || outputStringArray[index] === "\n") {
            whiteSpaceCounter++
        } else {
            whiteSpaceCounter = 0;
        }
        if (whiteSpaceCounter >= 2 && (outputStringArray[index] === " " || outputStringArray[index] === "\n")) {
            outputStringArray[index] = "";
        }
        index++;
    }
    return outputStringArray.join("")
}

const getCharIndexes = (inputString, char) => {
    const indexes = [];
    for (let i = 0; i < inputString.length; i++) {
        if (inputString[i] === char) {
            indexes.push(i);
        }
    }
    return indexes;
}