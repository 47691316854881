import { SET_UPLOADED_SUBTITLES, ADD_COMMENT_UPLOADED } from "./types"

export const setUploadedSubtitles = (uploadedSubtitles) => {
    return {
        type: SET_UPLOADED_SUBTITLES,
        payload: uploadedSubtitles
    }
}


export const addCommentToUploadedSubtitle = (subtitleId, comment) => {
    return {
        type: ADD_COMMENT_UPLOADED,
        payload: {
            subtitleId,
            comment
        }
    }
}
