import { userSettings } from "../actions/types"

const initialState = {
    listeningForKeyPress: false,
    videoPlayPauseKey: 80,
    nextSubtitleKey: 78,
    previousSubtitleKey: 66,
    microphoneKey: 82,
}

function userSettingsReducer(state = initialState, action) {
    switch (action.type) {
        case userSettings.SET_USER_SETTINGS:
            return {
                ...state,
                videoPlayPauseKey: action.payload.videoPlay,
                nextSubtitleKey: action.payload.nextSubtitle,
                previousSubtitleKey: action.payload.previousSubtitle,
                microphoneKey: action.payload.microphoneToggle
            }
        case userSettings.SET_VIDEO_PLAY_PAUSE_KEY:
            return {
                ...state,
                videoPlayPauseKey: action.payload
            }
        case userSettings.SET_NEXT_SUBTITLE_KEY:
            return {
                ...state,
                nextSubtitleKey: action.payload
            }
        case userSettings.SET_PREVIOUS_SUBTITLE_KEY:
            return {
                ...state,
                previousSubtitleKey: action.payload
            }
        case userSettings.SET_MICROPHONE_KEY:
            return {
                ...state,
                microphoneKey: action.payload
            }
        case userSettings.SET_LISTENING_FOR_KEY:
            return {
                ...state,
                listeningForKeyPress: action.payload
            }
        default:
            return state
    }
}

export default userSettingsReducer;