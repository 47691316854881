import { SET_UPLOADED_SUBTITLES, ADD_COMMENT_UPLOADED } from "../actions/types"

const initialState = {
    uploadedSubtitles: []
}

function uploadedSubtitlesReducer(state = initialState, action) {
    switch (action.type) {
        case SET_UPLOADED_SUBTITLES:
            return {
                ...state,
                uploadedSubtitles: action.payload
            }
        case ADD_COMMENT_UPLOADED:
            return {
                ...state,
                uploadedSubtitles: state.uploadedSubtitles.map(
                    (sub, i) => sub.id == action.payload.subtitleId ? { ...sub, comment: action.payload.comment } : sub
                )
            }
        default:
            return state
    }
}

export default uploadedSubtitlesReducer;