import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getRecordingsForProject, deleteRecordingForProject, deleteRecordingBlobOnServer } from '../../actions/recordingActions'
import { Button } from 'react-bootstrap'
import Swal from 'sweetalert2'
import download from 'downloadjs'

import './recordings.css'
import { apiUrl } from '../../constants'

const Recordings = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const videoRef = useRef()

    const projectRec = useSelector(state => state.recordingReducers.chosenRecProject)
    const [loadingDownload, setLoadingDownload] = useState([]);
    const [recordings, setRecordings] = useState([])
    const [selectedSource, setSelectedSource] = useState('')
    const [isLoading, setIsLoding] = useState("")

    const deleteRecording = (recordingPath, recordingId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(res => {
            if (res.isConfirmed) {
                console.log(recordingPath)
                // dispatch(deleteRecordingForProject(projectRec, recordingPath, recordingId))
                dispatch(deleteRecordingBlobOnServer(recordingPath, projectRec,recordingId))
                    .then(res => {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: 'Recording deleted',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        dispatch(getRecordingsForProject(projectRec))
                            .then(res => {
                                setRecordings(res)
                            })
                    })
                    .catch(err => {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: err,
                            showConfirmButton: false,
                            timer: 1500
                        })
                    })
            }
        })
    }

    const downloadRecordingHandler = async (recording, i) => {
        setLoadingDownload([...loadingDownload, i]);
        let blob = await fetch(recording.recording).then(r => r.blob());
        setLoadingDownload(loadingDownload.filter(el => i != el));
        download(blob, `${recording.name}`, "video/mp4");
    }

    useEffect(() => {
        if (projectRec == undefined || projectRec == null || projectRec == "") {
            history.push('/projects')
        } else {
            setIsLoding(true)
            dispatch(getRecordingsForProject(projectRec))
                .then(res => {
                    setRecordings(res)
                    setIsLoding(false)
                })
                .catch(err => {
                    setIsLoding(false)
                })
        }
    }, [])

    useEffect(() => {
        videoRef.current.load()
    }, [selectedSource])

    if (isLoading) {
        return <div className="spinner"></div>
    }

    return (
        <div className="all-recordings">
            <p>Recordings</p>
            <div className="video-div">
                <video width="800" height="500" controls ref={videoRef}>
                    <source src={selectedSource}></source>
                </video>
            </div>
            {
                recordings.recordings?.length > 0 ? (
                    <div className="recordings-grid-view">
                        {
                            recordings.recordings?.map((record, i) => {
                                return (
                                    <div key={i + 1} className="single-recording">
                                        <h6>{record.name}</h6>
                                        <p>{record.timestamp.split('T')[0]}</p>
                                        <p>Duration: {record.duration}</p>
                                        <Button onClick={() => setSelectedSource(record.recording)}>Load</Button>
                                        <Button disabled={loadingDownload.includes(i)} variant="success" onClick={() => downloadRecordingHandler(record, i)}><p style={{margin: "0"}}>{loadingDownload.includes(i) ? "Downloading" : "Download"}</p></Button>
                                        {recordings.deletePermission && <Button variant="danger" onClick={() => deleteRecording(record.recording, record._id)}>Delete</Button>}
                                    </div>
                                )
                            })
                        }
                    </div>
                ) : (
                    <h3 className="warning-message-recording">No recordings available</h3>
                )
            }
        </div>
    )
}

export default Recordings