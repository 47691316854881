import axios from "axios"
import { apiUrl } from "../constants"

export const translateText = (textToTranslate, targetLanguageCode) => dispatch => {
    return new Promise((resolve, reject) => {
        axios.post(`${apiUrl}/translate`, { textToTranslate, targetLanguageCode })
            .then(res => {
                resolve({
                    translatedText: res.data.translatedText
                });
            })
            .catch(err => {
                reject({ msg: err?.response?.data?.msg ?? "Server error!" })
            })
    })
}