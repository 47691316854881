import React, { useState, useEffect } from 'react'
import { Modal, Form, Button } from 'react-bootstrap'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import Swal from 'sweetalert2'
import { useHistory } from 'react-router-dom'
import { apiUrl } from '../../../constants'

import { setUploadedSubtitles } from '../../../actions/uploadedSubtitlesActions'
import { setSubtitleProperties } from '../../../actions/subtitlePropertiesActions'
import { setTranslatedSubtitles } from '../../../actions/translatedSubtitlesActions'
import { setCurrentProject } from '../../../actions/currentProjectAction'

import { getAllProjects } from '../../../actions/projectActions'

const ModalAddProject = ({ showModalAddProject, setShowModalAddProject, userGroups }) => {
    const history = useHistory()
    const dispatch = useDispatch()
    const [projectName, setProjectName] = useState("")
    const [projectThumbnail, setProjectThumbnail] = useState("")
    const [videoUrl, setVideoUrl] = useState("")
    const moderatedGroups = useSelector(state => state.authorizationReducer.moderatedGroups)
    const [file, setFile] = useState("")
    const [thumbnailFile, setThumbnailFile] = useState("")
    const [selectedGroupId, setSelectedGroupId] = useState(null)
    const [videoFile, setVideoFile] = useState("")

    const validateUserInput = () => {
        let validation = true
        let errorMessage = ""
        if (file == "" || file == undefined) {
            validation = false
            errorMessage = "File is not uploaded"
        }
        if (projectName == "") {
            validation = false
            errorMessage = "Project name can't be empty"
        }
        if (thumbnailFile == "" || thumbnailFile == undefined) {
            validation = false
            errorMessage = "Thumbnail is required"
        }
        // if ((videoFile == "" || file == undefined) && (videoUrl == "" || videoUrl == undefined)) {
        //     validation = false
        //     errorMessage = "Video file or url is not provided"
        // }
        return [validation, errorMessage]
    }

    const handleSubmit = () => {
        let formdata = new FormData()
        if (videoFile != "") {
            Swal.fire({
                title: 'Saving video to cloud...',
                allowOutsideClick: false,
                allowEscapeKey: false,
                didOpen: () => {
                    Swal.showLoading()
                },
            })
            formdata.append('videoFile', videoFile)
            axios({
                url: `${apiUrl}/gcs/upload-movie`,
                method: "POST",
                data: formdata
            })
                .then(res => {
                    console.log("RES: ", res)
                    let validation = validateUserInput()
                    let videoUrlUploaded = res.data.url
                    if (validation[0] == false) {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: validation[1],
                            showConfirmButton: false,
                            timer: 1500,
                        })
                        return
                    }
                    let formdata = new FormData()
                    formdata.append('file', file)
                    formdata.append('thumbFile', thumbnailFile)
                    formdata.append('projectName', projectName)
                    formdata.append('projectThumbnail', projectThumbnail)
                    formdata.append('videoUrl', videoUrlUploaded)
                    formdata.append('groupId', selectedGroupId)
                    axios({
                        url: `${apiUrl}/projects/add_new`,
                        method: "POST",
                        data: formdata
                    })
                        .then(res => {
                            Swal.fire({
                                position: 'top-end',
                                icon: 'success',
                                title: res.data.msg,
                                showConfirmButton: false,
                                timer: 1500
                            })
                            dispatch(getAllProjects())
                            dispatch(setUploadedSubtitles(res.data.project.uploaded_subtitles))
                            dispatch(setTranslatedSubtitles(res.data.project.translated_subtitles))
                            dispatch(setSubtitleProperties({
                                subsTitle: res.data.project.subtitle_name,
                                videoUrl: res.data.project.videoUrl
                            }))
                            dispatch(setCurrentProject(res.data.project))
                            history.push('/')
                            setShowModalAddProject(false)
                        })
                        .catch(err => {
                            console.log(err)
                            Swal.fire({
                                position: 'top-end',
                                icon: 'error',
                                title: err?.response?.data?.msg ?? "Server error!",
                                showConfirmButton: false,
                                timer: 2000
                            })
                        })
                })
                .catch(err => {
                    console.log(err)
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: "Failed to save movie to cloud.",
                        showConfirmButton: false,
                        timer: 2000
                    })
                })
        } else {
            let validation = validateUserInput()
            if (validation[0] == false) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: validation[1],
                    showConfirmButton: false,
                    timer: 1500,
                })
                return
            }
            let formdata = new FormData()
            formdata.append('file', file)
            formdata.append('thumbFile', thumbnailFile)
            formdata.append('projectName', projectName)
            formdata.append('projectThumbnail', projectThumbnail)
            formdata.append('videoUrl', videoUrl)
            formdata.append('groupId', selectedGroupId)
            axios({
                url: `${apiUrl}/projects/add_new`,
                method: "POST",
                data: formdata
            })
                .then(res => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: res.data.msg,
                        showConfirmButton: false,
                        timer: 1500
                    })
                    dispatch(getAllProjects())
                    dispatch(setUploadedSubtitles(res.data.project.uploaded_subtitles))
                    dispatch(setTranslatedSubtitles(res.data.project.translated_subtitles))
                    dispatch(setSubtitleProperties({
                        subsTitle: res.data.project.subtitle_name,
                        videoUrl: res.data.project.videoUrl
                    }))
                    dispatch(setCurrentProject(res.data.project))
                    history.push('/')
                    setShowModalAddProject(false)
                })
                .catch(err => {
                    console.log(err)
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: err?.response?.data?.msg ?? "Server error!",
                        showConfirmButton: false,
                        timer: 2000
                    })
                })
        }
    }

    return (
        <div>
            {/* <Modal show={true} onHide={() => handleCloseLogin()} aria-labelledby="contained-modal-title-vcenter" centered> */}
            <Modal show={showModalAddProject} onHide={() => setShowModalAddProject(false)} aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header closeButton>
                    {/* <Modal.Header closeButton onClick={() => handleCloseLogin()}> */}
                    <Modal.Title>Add new project</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={false}>
                        <Form.Group controlId="formBasic">
                            <Form.Label>Project name</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Enter project name..."
                                onChange={(input) => setProjectName(input.target.value)}
                            />
                            <Form.Control.Feedback></Form.Control.Feedback>
                        </Form.Group>

                        <Form.Label>Add video file</Form.Label>
                        <Form.File disabled={videoUrl != ""} onChange={(file) => {
                            if (file.target.files.length == 0) {
                                setVideoFile("")
                            } else {
                                setVideoFile(file.target.files[0])
                            }
                        }
                        }>
                        </Form.File>

                        <Form.Group controlId="formBasic">
                            <Form.Label>Video URL</Form.Label>
                            <Form.Control
                                disabled={videoFile != ""}
                                type="text"
                                placeholder="Enter video URL..."
                                onChange={(input) => setVideoUrl(input.target.value)}
                            />
                        </Form.Group>

                        {/* <Form.Group controlId="formBasic">
                        <Form.Label>Thumbnail</Form.Label>
                        <Form.Control 
                        type="text" 
                        placeholder="Enter thumbnail (optional)..."
                        onChange={(input) => setProjectThumbnail(input.target.value)}
                        />
                        <Form.Control.Feedback></Form.Control.Feedback>
                    </Form.Group> */}

                        <Form.Label>Add thumbnail file</Form.Label>
                        <Form.File onChange={(file) => setThumbnailFile(file.target.files[0])}>
                        </Form.File>

                        <Form.Label>Add subtitle file</Form.Label>
                        <Form.File onChange={(file) => setFile(file.target.files[0])}>
                        </Form.File>

                        {(moderatedGroups && moderatedGroups.length > 0) &&
                        <Form.Group controlId="groupSelect">
                            <Form.Label>Select a group the project should belong to</Form.Label>
                            <Form.Control onChange={(input) => setSelectedGroupId(input.target.value)} as="select">
                                <option value="" selected>No group (personal)</option>
                                {userGroups.map(g => {
                                    return <option value={g._id}>{g.group_name}</option>
                                })}
                            </Form.Control>
                        </Form.Group>
                        }
                    </Form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModalAddProject(false)}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={() => handleSubmit()}>
                        Add
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ModalAddProject
