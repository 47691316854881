import axios from 'axios'
import { ADD_PHRASE, CLEAR_MESSAGE, SET_PHRASES, SET_ALL_PHRASES, SET_DICT_WORDS } from './types'

import { socket } from "../socket";
import { apiUrl } from '../constants'

export const addPhrase = (phrase) => dispatch => {
    return new Promise((resolve, reject) => {
        let loggedUsername = localStorage.getItem('username');
        axios.post(`${apiUrl}/phrases/add`, {
            phrase,
            username: loggedUsername
        })
        .then(res => {
            dispatch({
                type: ADD_PHRASE,
                payload: res.data.msg
            })
            dispatch(setPhrases())
            resolve()
        })
        .catch(err => {
            reject()
        })
    })
}

export const clearMessage = () => {
    return {
        type: CLEAR_MESSAGE
    }
}

export const getPhrases = () => dispatch => {
    let loggedUsername = localStorage.getItem('username');
    axios.post(`${apiUrl}/phrases/get_all`, {
        username: loggedUsername
    })
    .then(res => {
        dispatch({
            type: SET_PHRASES,
            payload: res.data.phrases
        })
    })
}

export const setPhrases = () => dispatch => {
    let loggedUsername = localStorage.getItem('username');
    axios.post(`${apiUrl}/phrases/set`, {
        username: loggedUsername
    })
    .then(res => {
        if(res.data && res.data.user && res.data.user.phrases) {
            dispatch({
                type: SET_ALL_PHRASES,
                payload: res.data.user.phrases
            })
            socket.emit('setPhrases', res.data.user.phrases)
        }
    })
}

export const updatePhrase = (phrase, newPhrase) => dispatch => {
    return new Promise((resolve, reject) => {
        axios.post(`${apiUrl}/phrases/edit_phrase`, {
            phrase,
            newPhrase,
        })
        .then(res => {
            dispatch(setPhrases())
            resolve()
        })
        .catch(err => {
            reject()
        })
    })
}

export const deletePhrase = (phrase) => dispatch => {
    let loggedUsername = localStorage.getItem('username');
    axios.post(`${apiUrl}/phrases/del`, {
        username: loggedUsername,
        phrase
    })
    .then(res => {
        dispatch({
            type: SET_ALL_PHRASES,
            payload: res.data.phrases
        })
        dispatch(setPhrases())
    })
    .catch(err => {
        console.log(err)
    })
}

export const getDictWords = () => dispatch => {
    axios.post(`${apiUrl}/dict/get_all`)
    .then(res => {
        dispatch({
            type: SET_DICT_WORDS,
            payload: res.data.dictWords
        })
    })
    .catch(err => {
        console.log(err)
    })
}

export const addDictWord = (problemWord, solutionWords) => dispatch => {
    var splitedWords = solutionWords.split(",").map(function(item) {
        return item.trim();
    });
    return new Promise((resolve, reject) => {
        axios.post(`${apiUrl}/dict/add_dict_word`, { 
            problemWord,
            splitedWords
        })
        .then(res => {
            dispatch({
                type: SET_DICT_WORDS,
                payload: res.data.dictWords
            })
            dispatch(getDictWords())
            resolve()
        })
        .catch(err => {
            reject()
        })
    })
}

export const editDictWord = (dictWord, problemWord, solutionWords) => dispatch => {
    solutionWords = solutionWords.split(',').map(item => item.trim())
    return new Promise((resolve, reject) => {
        axios.post(`${apiUrl}/dict/edit_dict_word`, {
            dictWord,
            problemWord,
            solutionWords
        })
        .then(res => {
            dispatch({
                type: SET_DICT_WORDS,
                payload: res.data.dictWords
            })
            dispatch(getDictWords())
            resolve()
        })
        .catch(err => {
            reject()
        })
    })
}

export const deleteDictWord = (dictWord) => dispatch => {
    axios.post(`${apiUrl}/dict/del_dict_word`, {
        dictWord
    })
    .then(data => {
        dispatch(getDictWords())
    })
}