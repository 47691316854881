import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector }  from 'react-redux'
import { Button, Modal, Form } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { registerUser, clearErrors } from '../../actions/authActions'
import axios from 'axios'

import './registrationPage.css'
import Swal from 'sweetalert2'

function ModalRegistration({ showRegistration, setShowRegistration, loadUsers }) {
    const [username, setUsername] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [rePassword, setRePassword] = useState("")
    const [error, setError] = useState("")
    const [apiFile, setApiFile] = useState("")

    const registrationError = useSelector(state => state.errorReducer)
    const authData = useSelector(state => state.authorizationReducer)

    const dispatch = useDispatch()
    const history = useHistory()

    const handleCloseRegistration = () => {
        setShowRegistration(false)
    }

    const registrationValidation = (e) => {
        e.preventDefault()
        // if(username.length != 0 && email.length != 0 && password.length != 0 && rePassword.length != 0 && apiFile.target && apiFile.target.files) {
        if(username.length != 0 && password.length != 0 && rePassword.length != 0) {
            if(password != rePassword) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "Passwords doesn't match",
                    showConfirmButton: false,
                    timer: 2000
                })
            } else {
                // if(apiFile.target.files[0]) {
                dispatch(registerUser({
                    username,
                    email,
                    password,
                    // file: apiFile.target.files[0]
                }))
                .then(res => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: res,
                        showConfirmButton: false,
                        timer: 1500
                    })
                    clearRegistration()
                    handleCloseRegistration()
                    loadUsers()
                })
                .catch(err => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: err,
                        showConfirmButton: false,
                        timer: 1500
                    })
                })
                // }
            }
            setError("")
        } else {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: "All fields are required",
                showConfirmButton: false,
                timer: 1500
            })
        }
    }

    const clearRegistration = () => {
        setUsername("")
        setEmail("")
        setPassword("")
        setRePassword("")
        setApiFile("")
        setError("")
        dispatch(clearErrors())
    }

    // useEffect(() => {
    //     if(authData.isLogged) {
    //         clearRegistration()
    //         history.push('/projects')
    //     }
    // }, [authData])

    return (
        <Modal show={showRegistration} onHide={handleCloseRegistration}>
            <Modal.Header closeButton>
                <Modal.Title>Add new user</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate className="registration-form">
                    <Form.Group controlId="formBasicEmail">
                        <Form.Label>ISV code</Form.Label>
                        <Form.Control 
                        required
                        type="text" 
                        value={username}
                        placeholder="Enter username" 
                        onChange={(input) => setUsername(input.target.value)}/>
                        <Form.Control.Feedback></Form.Control.Feedback>
                    </Form.Group>

                    {/* <Form.Group controlId="formBasicEmail">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control 
                        required
                        value={email}
                        type="email" 
                        placeholder="Enter email" 
                        onChange={(input) => setEmail(input.target.value)}/>
                        <Form.Control.Feedback></Form.Control.Feedback>
                    </Form.Group> */}

                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>Password</Form.Label>
                        <Form.Control 
                        required
                        value={password}
                        type="password" 
                        placeholder="Password"
                        onChange={(input) => setPassword(input.target.value)}
                        />
                        <Form.Control.Feedback></Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword">
                        <Form.Label>Repeat password</Form.Label>
                        <Form.Control 
                        required
                        value={rePassword}
                        type="password" 
                        placeholder="Repeat password"
                        onChange={(input) => setRePassword(input.target.value)}
                        />
                        <Form.Control.Feedback></Form.Control.Feedback>
                    </Form.Group>
                    {/* <Form.Group>
                        <Form.File id="google-api-key" label="Import Google Key" 
                        onChange={
                            (file) => setApiFile(file)
                        }/>
                    </Form.Group> */}
                    <Button variant="primary" onClick={(e) => registrationValidation(e)}>Add user</Button>
                    <Button variant="secondary" style={{ marginLeft: "10px"}} onClick={() => handleCloseRegistration()}>Cancel</Button>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default ModalRegistration
