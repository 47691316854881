import axios from "axios"
import { apiUrl } from "../constants"

export const spellcheck = (text) => dispatch => {
    return new Promise((resolve, reject) => {
        axios.post(`${apiUrl}/spellcheck`, { text })
            .then(res => {
                resolve({
                    wordList: res.data.wordList
                });
            })
            .catch(err => {
                reject({ msg: err?.response?.data?.msg ?? "Server error!" })
            })
    })
}