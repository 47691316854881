import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getPhrases, deletePhrase, deleteDictWord, getDictWords } from '../../actions/wordsActions'
import { Container, Row, Col, Button } from 'react-bootstrap'
import Swal from 'sweetalert2'
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar'
import { MDBDataTable } from 'mdbreact'

import DictionaryWord from '../navbar/addingWords/dictionaryWord'
import { editDictWord, updatePhrase } from '../../actions/wordsActions'
import PhraseWord from '../navbar/addingWords/phraseWord'

import './phrases.css'
import axios from 'axios'
import { apiUrl } from '../../constants'


const Phrases = () => {
    const dispatch = useDispatch()
    const phrases = useSelector(state => state.wordReducer.phrases)
    const dictWords = useSelector(state => state.wordReducer.dictWords)

    const [showDictionaryWord, setShowDictionaryWord] = useState(false)
    const [dictWord, setDictWord] = useState("")
    const [showAddPhraseWord, setShowAddPhraseWord] = useState(false)
    const [editPhrase, setEditPhrase] = useState("")

    const [phraseData, setPhraseData] = useState({
        columns: [
            {
                label: 'Phrase',
                field: 'phrase',
            },
            {
                label: "Edit",
                field: 'editBtn',

            },
            {
                label: 'Delete',
                field: 'deleteBtn',
            },
        ],
        rows: []
    })

    const [wordData, setWordData] = useState({
        columns: [
            {
                label: 'Problem word',
                field: 'problemWord',
            },
            {
                label: "Solution words",
                field: 'solutionWords',

            },
            {
                label: "Edit",
                field: 'editBtn',

            },
            {
                label: 'Delete',
                field: 'deleteBtn',
            },
        ],
        rows: []
    })

    const handlePhraseDelete = (phrase) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(res => {
            if (res.isConfirmed) {
                dispatch(deletePhrase(phrase))
                dispatch(getPhrases())
            }
        })
    }

    const handleDictWordDelete = (dictWord) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(res => {
            if (res.isConfirmed) {
                dispatch(deleteDictWord(dictWord))
                dispatch(getDictWords())
            }
        })
    }

    const handlePhraseEdit = (phrase) => {
        setEditPhrase(phrase)
        setShowAddPhraseWord(true)
    }

    const handleEditPhrase = (phrase) => {
        dispatch(updatePhrase(editPhrase, phrase))
            .then(res => {
                console.log(res)
            })
            .catch(err => {
                console.log(err)
            })
        setShowAddPhraseWord(false)
    }

    const handleEditDictWord = (dictWord) => {
        setDictWord(dictWord)
        setShowDictionaryWord(true)
    }

    const handleUpdateDictionaryWord = (problemWord, solutionWords) => {
        dispatch(editDictWord(dictWord, problemWord, solutionWords))
            .then(res => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: "Word is updated",
                    showConfirmButton: false,
                    timer: 1500,
                })
            })
            .catch(err => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "Failed to update word",
                    showConfirmButton: false,
                    timer: 1500
                })
            })
        setShowDictionaryWord(false)
    }

    const handleCloseDictionaryWord = () => {
        setShowDictionaryWord(false)
    }

    const handleCloseAddPhraseWord = () => {
        setShowAddPhraseWord(false)
    }

    const handleShowDictionaryWord = () => {
        setDictWord("")
        setShowDictionaryWord(true)
    }

    const handleShowAddPhraseWord = () => {
        setEditPhrase("")
        setShowAddPhraseWord(true);
    }

    const preparePhrasesForTable = () => {
        let dataList = []
        phrases.map(item => {
            let row = {}
            row.phrase = item
            row.editBtn = <Button size="sm" variant="primary" onClick={() => handlePhraseEdit(item)}>Edit</Button>
            row.deleteBtn = <Button size="sm" variant="danger" onClick={() => handlePhraseDelete(item)}>Delete</Button>
            dataList.push(row)
        })
        setPhraseData({
            ...phraseData,
            rows: dataList
        })
    }

    const prepareWordsForTable = () => {
        let dataList = []
        dictWords.map(word => {
            let row = {}
            row.problemWord = word.problem_word
            row.solutionWords = word.solution_words.map((soltuionWord, i) => {
                if (word.solution_words.length - 1 == i) {
                    return <span key={soltuionWord}>{soltuionWord}</span>
                } else {
                    return <span key={soltuionWord}>{soltuionWord}, </span>
                }
            })
            row.editBtn = <Button variant="primary" size="sm" onClick={() => handleEditDictWord(word)}>Edit</Button>
            row.deleteBtn = <Button variant="danger" size="sm" onClick={() => handleDictWordDelete(word)}>Delete</Button>
            dataList.push(row)
        })
        setWordData({
            ...wordData,
            rows: dataList
        })
    }

    useEffect(() => {
        preparePhrasesForTable()
    }, [phrases])

    useEffect(() => {
        prepareWordsForTable()
    }, [dictWords])

    // border: 1px solid white;

    return (
        <div style={{ height: '100vh' }} className="words-div">
            <div className="sidebar" style={{ display: "inline-block" }}>
                <ProSidebar
                    collapsed={false}
                >
                    <Menu iconShape="square">
                        <MenuItem><Button size="sm" onClick={() => handleShowAddPhraseWord()}>Add Phrase</Button></MenuItem>
                        <MenuItem><Button size="sm" onClick={() => handleShowDictionaryWord()}>Add Word</Button></MenuItem>
                    </Menu>
                </ProSidebar>
            </div>
            <Row className="dict-words-div">
                <Col className="table-col-words">
                    <h3>List of automatic replacement words of user</h3>
                    <MDBDataTable
                        data={phraseData}
                    />
                </Col>

                <Col className="table-col-words">

                    <h3>List of problematic words</h3>
                    <MDBDataTable
                        data={wordData}
                    />
                </Col>
            </Row>
            <DictionaryWord
                handleUpdateDictionaryWord={handleUpdateDictionaryWord}
                showDictionaryWord={showDictionaryWord}
                handleCloseDictionaryWord={handleCloseDictionaryWord}
                dictWord={dictWord}
            />
            <PhraseWord
                showAddPhraseWord={showAddPhraseWord}
                handleCloseAddPhraseWord={handleCloseAddPhraseWord}
                editPhrase={editPhrase}
                handleEditPhrase={handleEditPhrase}
            />
        </div>
    )
}

export default Phrases
