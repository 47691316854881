import { SET_INITIAL_TRANSLATED_SUBTITLES, CHANGE_IMUTABLY, ADD_COMMENT, ADD_COMMENT_UPLOADED, STORE_COMMENT_ID } from "./types"

export const setTranslatedSubtitles = (translatedSubtitle) => {
    return {
        type: SET_INITIAL_TRANSLATED_SUBTITLES,
        payload: translatedSubtitle
    }
}

export const updateTranslatedSubtitles = (subtitleId, subtitleText) => {
    return {
        type: CHANGE_IMUTABLY,
        payload: {
            subtitleId,
            subtitleText,
        }
    }
}

export const addCommentToSubtitle = (subtitleId, comment) => {
    return {
        type: ADD_COMMENT,
        payload: {
            subtitleId,
            comment
        }
    }
}

export const storeCommentId = (id) => ({
    type: STORE_COMMENT_ID,
    payload: id,
});