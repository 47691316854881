import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import Swal from 'sweetalert2'

import { getSnapshotsForProject, deleteSnapshot, editSnapshot } from '../../actions/snapshotActions'
import { setUploadedSubtitles } from '../../actions/uploadedSubtitlesActions'
import { setTranslatedSubtitles } from '../../actions/translatedSubtitlesActions'
import { setSubtitleProperties } from '../../actions/subtitlePropertiesActions'
import { setCurrentProject } from '../../actions/currentProjectAction'
import './snapshots.css'

const Snapshots = () => {
    const dispatch = useDispatch()
    const history = useHistory()

    const projectSnapshots = useSelector(state => state.snapshotReducer.chosenSnapshotProject)
    const [isLoading, setIsLoding] = useState(true)
    const [snapshots, setSnapshots] = useState([])

    useEffect(() => {
        if (projectSnapshots == undefined || projectSnapshots == null || projectSnapshots == "") {
            history.push('/projects')
        } else {
            setIsLoding(true)
            dispatch(getSnapshotsForProject(projectSnapshots))
                .then(res => {
                    console.log(res.snapshots);
                    setSnapshots(res.snapshots)
                    setIsLoding(false)
                })
                .catch(err => {
                    setIsLoding(false)
                })
        }
    }, []);

    const loadSnapshotHandler = (snapshot) => {
        dispatch(setUploadedSubtitles(snapshot.uploaded_subtitles))
        dispatch(setTranslatedSubtitles(snapshot.translated_subtitles))
        dispatch(setSubtitleProperties({
            subsTitle: snapshot.subtitle_name,
            videoUrl: snapshot.videoUrl,
            lastEdited: 1
        }, false))
        dispatch(setCurrentProject(snapshot))
        history.push('/')
    }

    const editSnapshotHandler = async (snapshot) => {
        const { value: snapshotName } = await Swal.fire({
            title: 'Enter new title for your snapshot',
            input: 'text',
            inputLabel: 'Snapshot title',
            inputValue: snapshot.subtitle_name,
            inputPlaceholder: 'Almost done with 2nd half'
        });
        if (snapshotName) {
            dispatch(editSnapshot(snapshot._id, snapshotName))
                .then(() => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Snapshot saved!',
                        showConfirmButton: false,
                        timer: 1500
                    })
                    dispatch(getSnapshotsForProject(projectSnapshots))
                        .then(res => {
                            setSnapshots(res.snapshots)
                        })
                })
                .catch(() => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong, try again!',
                    })
                });
        }
    }

    const deleteSnapshotHandler = (snapshotId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it.'
        }).then(res => {
            if (res.isConfirmed) {
                dispatch(deleteSnapshot(snapshotId))
                    .then(res => {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'success',
                            title: 'Snapshot deleted.',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        dispatch(getSnapshotsForProject(projectSnapshots))
                            .then(res => {
                                setSnapshots(res.snapshots)
                            })
                    })
                    .catch(err => {
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: err,
                            showConfirmButton: false,
                            timer: 1500
                        })
                    })
            }
        })
    }

    if (isLoading) {
        return <div className="spinner" />
    }

    return (
        <div className="all-snapshots">
            {
                snapshots.length > 0 ? (
                    <div className="snapshots-grid-view">
                        {
                            snapshots.map((snapshot, i) => {
                                return (
                                    <div key={i + 1} className="single-snapshot">
                                        <h5>{snapshot.subtitle_name}</h5>
                                        <p>{snapshot.createdAt.split('T')[0] + " - " + snapshot.createdAt.split('T')[1].split('Z')[0]}</p>
                                        <Button onClick={() => loadSnapshotHandler(snapshot)}>Load</Button>
                                        <Button variant="success" onClick={() => editSnapshotHandler(snapshot)}>Edit</Button>
                                        <Button variant="danger" onClick={() => deleteSnapshotHandler(snapshot._id)}>Delete</Button>
                                    </div>
                                )
                            })
                        }
                    </div>
                ) : (
                    <h3 className="warning-message-snapshot">No snapshots available!</h3>
                )
            }
        </div>
    )
}

export default Snapshots
