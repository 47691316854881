import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Form, Button } from 'react-bootstrap'
import Swal from 'sweetalert2'
import axios from 'axios'
import { apiUrl } from '../../../constants'

import './editProject.css'
import { updateProjectChanges, getAllProjects } from '../../../actions/projectActions'

const EditProject = ({ editingProject, setIsEditing }) => {
    const dispatch = useDispatch()

    const [subtitleName, setSubtitleName] = useState("")
    const [thumbnail, setThumbnail] = useState("")
    const [videoUrl, setVideoUrl] = useState("")
    const [file, setFile] = useState("")
    const [thumbnailFile, setThumbnailFile] = useState("")
    const [videoFile, setVideoFile] = useState("")

    const saveChanges = () => {
        if (videoFile != "" && videoFile != undefined) {
            let formdata = new FormData()
            formdata.append('videoFile', videoFile)
            axios({
                url: `${apiUrl}/gcs/upload-movie`,
                method: "POST",
                data: formdata
            })
                .then(res => {
                    console.log("RES: ", res)
                    let videoUrlUploaded = res.data.url
                    let updatedProject = {
                        projectId: editingProject._id,
                        projectName: subtitleName,
                        thumbnail,
                        videoUrl: videoUrlUploaded,
                    }
                    updateProject(updatedProject)
                })
                .catch(err => {

                })
        } else {
            let updatedProject = {
                projectId: editingProject._id,
                projectName: subtitleName,
                thumbnail,
                videoUrl,
            }
            updateProject(updatedProject)
        }
    }

    const updateProject = (updatedProject) => {
        let formData = new FormData();
        if ((thumbnailFile == "" || thumbnailFile == undefined) && (file == "" || file == undefined)) {
            dispatch(updateProjectChanges(updatedProject))
                .then(res => {
                    setIsEditing(false)
                    dispatch(getAllProjects())
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: res,
                        showConfirmButton: false,
                        timer: 1500
                    })
                })
                .catch(err => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: err,
                        showConfirmButton: false,
                        timer: 1500
                    })
                })
        }
        else {
            if (file != "" && file != undefined) {
                formData.append("file", file)
            }
            if (thumbnailFile != "" && thumbnailFile != undefined) {
                formData.append("thumbFile", thumbnailFile)
            }
            updatedProject = JSON.stringify(updatedProject)
            formData.append('updatedProject', updatedProject)
            dispatch(updateProjectChanges(formData))
                .then(res => {
                    setIsEditing(false)
                    dispatch(getAllProjects())
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: res,
                        showConfirmButton: false,
                        timer: 1500
                    })
                })
                .catch(err => {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: err,
                        showConfirmButton: false,
                        timer: 1500
                    })
                })
        }
    }

    useEffect(() => {
        setSubtitleName(editingProject?.subtitle_name)
        setThumbnail(editingProject?.thumbnail)
        setVideoUrl(editingProject?.videoUrl)
        // return() => {
        //     dispatch(getAllProjects())
        // }
    }, [])

    return (
        <div className="editing-div">
            <div className="editing-header">
                <h3>Editing project: <b>{editingProject?.subtitle_name}</b></h3>
                <Button className="btn-x" variant="light" onClick={() => setIsEditing(false)}>X</Button>
            </div>
            <Form className="editing-form">
                <Form.Label>Project name</Form.Label>
                <Form.Control
                    value={subtitleName}
                    onChange={(input) => setSubtitleName(input.target.value)}
                />

                <Form.Label>Add video file</Form.Label>
                <Form.File disabled={videoUrl != ""} onChange={(file) => {
                    if (file.target.files.length == 0) {
                        setVideoFile("")
                    } else {
                        setVideoFile(file.target.files[0])
                    }
                }
                }>
                </Form.File>

                {/* <Form.Label>Project thumbnail</Form.Label>
                <Form.Control
                    value={thumbnail}
                    onChange={(input) => setThumbnail(input.target.value)}
                /> */}
                <Form.Group controlId="formBasic">
                    <Form.Label>Video URL</Form.Label>
                    <Form.Control
                        value={videoUrl}
                        disabled={videoFile != ""}
                        type="text"
                        placeholder="Enter video URL..."
                        onChange={(input) => setVideoUrl(input.target.value)}
                    />
                </Form.Group>

                <Form.Label>Upload thumbnail</Form.Label>
                <Form.File
                    onChange={(upload) => setThumbnailFile(upload?.target?.files[0])}
                ></Form.File>

                <Form.Label>Upload subtitles</Form.Label>
                <Form.File
                    onChange={(upload) => setFile(upload?.target?.files[0])}
                ></Form.File>
            </Form>
            <Button
                className="editing-save-btn"
                variant="primary"
                onClick={() => saveChanges()}
            >Save</Button>
        </div>
    )
}

export default EditProject
