import './home.css';
import React, { useState, useEffect } from 'react';
import axios from 'axios'
import download from 'js-file-download'
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';
import HomeSettings from '../homeSettings/homeSettings'
import VideoPlayer from '../videoPlayer/videoPlayer'
import Subtitles from '../subtitles/subtitles'

import * as Icon from 'react-bootstrap-icons';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { Container, Row, Col, Button, Form } from 'react-bootstrap'

import { useSelector, useDispatch } from 'react-redux';
import { setUploadedSubtitles } from '../../actions/uploadedSubtitlesActions';
import { setSubtitleProperties } from '../../actions/subtitlePropertiesActions'
import { setTranslatedSubtitles, setTranslatedSubtitlesUncontroled } from '../../actions/translatedSubtitlesActions'
import { setCurrentProject } from '../../actions/currentProjectAction'
import { loginUser } from '../../actions/authActions'
import { apiUrl } from '../../constants';
import { checkIfSaved, saveProcess } from '../../actions/savingActions'
import Swal from 'sweetalert2'

axios.defaults.withCredentials = true

const Home = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  // const translatedSubtitles = useSelector(state => state.translatedSubtitlesReducer.translatedSubtitles)
  const uploadedSubtitles = useSelector(state => state.uploadedSubtitlesReducer.uploadedSubtitles)
  const isLogged = useSelector(state => state.authorizationReducer.isLogged)
  const subtitleName = useSelector(state => state.subtitlePropertiesReducer.subsTitle)
  const currentProject = useSelector(state => state.currentProjectReducer.project)
  // State
  const [loadingSubtitles, setLoadingSubtitles] = useState(false)
  const theme = useSelector(state => state.settingsReducer.theme)
  // Methods
  // function updateTranslatedSubtitles(edited) {
  //   dispatch(setTranslatedSubtitles(translatedSubtitles.map(subtitle => {
  //     if (subtitle.id == edited.id) {
  //       subtitle = {...subtitle}
  //       subtitle.text = edited.text
  //     }
  //     return subtitle
  //   })))
  // }

  function handleFile(file) {
    if (file.target.files[0] != null) {
      let uploadedFile = file.target.files[0];
      if (uploadedFile.name.endsWith(".srt")) {
        handleUpload(uploadedFile);
        file.target.value = null
      } else {
        alert("File is not in right format.")
      }
    }
  }

  function handleUpload(file) {

    let formdata = new FormData()
    formdata.append('file', file)

    axios({
      url: `${apiUrl}/upload`,
      method: "POST",
      data: formdata
    })
      .then(res => {
        let data = res.data
        dispatch(setUploadedSubtitles(data.subs))
        removeSubtitlesForTranslatedTable(data.subs)
        setLoadingSubtitles(false)
        dispatch(setSubtitleProperties({
          url: `${apiUrl}/subtitles/${data.subsName}.vtt`,
          lang: 'en',
          subsTitle: data.subsTitle
        }))
      })
  }

  function removeSubtitlesForTranslatedTable(subs) {
    dispatch(setTranslatedSubtitles(subs.map(sub => ({ ...sub, text: "" }))));
  }

  // const handleDownload = () => {
  //   axios({
  //     url: `${apiUrl}/download-subtitles`,
  //     method: "POST",
  //     data: {
  //       subtitles: translatedSubtitles
  //     }
  //   })
  //   .then(res => {
  //     getSubtitles()
  //   })
  //   .catch(err => {
  //     console.log(err)
  //   })
  // }

  const getSubtitles = () => {
    axios({
      url: `${apiUrl}/download-subtitles/get-file`,
      method: "GET"
    })
      .then(res => {
        download(res.data, "translated_subtitles.srt")
      })
      .catch(err => {
        console.log(err)
      })
  }

  const checkUserAndProjectStatus = () => {
    axios.post(`${apiUrl}/auth/verify-auth`, {})
      .then(res => {
        dispatch(loginUser(res.data))
        if (!subtitleName) {
          history.push('/projects')
        }
      })
      .catch(err => {
        dispatch({
          type: "STOP_LOADING"
        })
        if (!history.location.pathname.includes("registration")) {
          history.push('/login')
        }
      })
  }

  const projectChosen = (project) => {
    dispatch(setUploadedSubtitles(project.uploaded_subtitles))
    dispatch(setTranslatedSubtitles(project.translated_subtitles))
    dispatch(setSubtitleProperties({
      subsTitle: project.subtitle_name,
      videoUrl: project.videoUrl,
      lastEdited: project.lastEdited
    }))
    dispatch(setCurrentProject(project))
    // history.push('/')
  }

  const loadSelectedProject = () => {
    let selectedProject = localStorage.getItem('project-name')
    if (selectedProject && !subtitleName) {
      axios.post(`${apiUrl}/projects/get_project`,
        {
          subtitle_name: selectedProject
        })
        .then(res => {
          if (res) {
            projectChosen(res.data)
          } else {
            history.push('/projects')
          }
        })
        .catch(err => {
          checkUserAndProjectStatus()
          history.push('/projects')
        })
    } else {
      if (!subtitleName) {
        history.push('/projects')
      }
    }
  }

  const checkIfChangesSaved = () => {
    if (!currentProject?.isSnapshot) {
      dispatch(checkIfSaved())
        .then(res => {
          if (!res) {
            Swal.fire({
              title: "Save changes!",
              text: "You didn't save changes, would you like to save them?",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, save it!'
            }).then(res => {
              if (res.isConfirmed) {
                dispatch(saveProcess(uploadedSubtitles, "", subtitleName, currentProject._id))
                  .then(res => {
                    Swal.fire({
                      position: 'top-end',
                      icon: 'success',
                      title: "Process saved!",
                      showConfirmButton: false,
                      timer: 1500
                    })
                    loadSelectedProject()
                  })
                  .catch(err => {
                    Swal.fire({
                      position: 'top-end',
                      icon: 'error',
                      title: err,
                      showConfirmButton: false,
                      timer: 1500
                    })
                  })
              }
            })
          }
        })
    }
  }

  useEffect(() => {
    loadSelectedProject()
    return () => {
      checkIfChangesSaved()
    }
  }, [])

  return (
    <React.Fragment>
      <div className="home-div" style={{ backgroundColor: '#1f2326', color: 'white' }} >
        <HomeSettings />
        <VideoPlayer />
        <Subtitles />
      </div>
    </React.Fragment>
  );
}

export default Home;
