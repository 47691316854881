import axios from 'axios'
import { apiUrl } from '../constants'

export const saveRecordingBlobOnServer = (chunkId, recordingName, recordingDuration) => (dispatch, getState) => {
    const projectName = getState().subtitlePropertiesReducer.subsTitle
    const form = new FormData()
    form.append('chunkId', chunkId)
    form.append('projectName', projectName)
    form.append('recordingName', recordingName)
    form.append('recordingDuration', recordingDuration)
    console.log("FORM:", form)
    return new Promise((resolve, reject) => {
        axios.post(`${apiUrl}/gcs/upload-recording-test`, form)
            .then(res => {
                resolve(res?.data?.msg)
            })
            .catch(err => {
                reject(err?.response?.data?.msg)
            })
    })
}

export const saveRecordingBlobPartOnServer = (blob, recordingId, recordingName, recordingDuration) => (dispatch, getState) => {
    const projectName = getState().subtitlePropertiesReducer.subsTitle
    const form = new FormData()
    form.append('blob', blob)
    form.append('recordingId', recordingId)
    form.append('tempRecordingName', `${recordingId}-${Date.now()}`)
    form.append('recordingName', recordingName)
    form.append('recordingDuration', recordingDuration)
    form.append('projectName', projectName)
    console.log("FORM:", form)
    return new Promise((resolve, reject) => {
        axios.post(`${apiUrl}/recording/upload-part`, form)
            .then(res => {
                resolve(res?.data?.msg)
            })
            .catch(err => {
                reject(err?.response?.data?.msg)
            })
    })
}

export const deleteRecordingBlobOnServer = (path, projectId, recordingId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        axios.post(`${apiUrl}/gcs/delete-recording`, { path, projectId, recordingId })
            .then(res => {
                resolve(res?.data?.msg)
            })
            .catch(err => {
                reject(err?.response?.data?.msg)
            })
    })
}

export const setCurrentProjectForRecordings = (project) => {
    return {
        type: 'SET_REC_PROJECT',
        payload: project
    }
}

export const getRecordingsForProject = (project_id) => dispatch => {
    return new Promise((resolve, reject) => {
        axios.post(`${apiUrl}/recording/get-recordings`, {
            project_id
        })
            .then(res => {
                resolve(res.data)
            })
            .catch(err => {
                reject(err?.response?.data?.msg)
            })

    })
}

export const deleteRecordingForProject = (project_id, recording, recordingId) => dispatch => {
    return new Promise((resolve, reject) => {
        axios.post(`${apiUrl}/recording/delete-recording`, {
            project_id,
            recording,
            recordingId
        })
            .then(res => {
                resolve()
            })
            .catch(err => {
                reject(err?.response?.data?.msg)
            })
    })
}