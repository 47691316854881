import { SET_CHOSEN_SUBTITLE } from "../actions/types"

const initialState = {
    chosenSubtitle: {
        id: "",
        text: "",
        start: "",
        end: ""
    }
}

function chosenSubtitleReducer(state = initialState, action) {
    switch(action.type) {
        case SET_CHOSEN_SUBTITLE:
            return {
                ...state,
                chosenSubtitle: {
                    ...state.chosenSubtitle,
                    id: action.payload.id,
                    text: action.payload.text,
                    start: action.payload.start,
                    end: action.payload.end
                }
            }
        default:
            return state
    }
}

export default chosenSubtitleReducer